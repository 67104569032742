<mat-dialog-content>
  <p>{{ "SORRY_NOT_AGREEING_TERMS" | transloco }}</p>
  <p>{{ "QUESTIONS_CONTACT_SUPPORT" | transloco }}</p>
  <a href="mailto:support@avelon.com">support&#64;avelon.com</a>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-dialog-close mat-flat-button>
    <span>{{ "BACK_TO_HOME_SCREEN" | transloco }}</span>
  </button>
  <button [mat-dialog-close]="true" cdkFocusInitial color="primary" mat-flat-button>
    <span>{{ "CLOSE" | transloco }}</span>
  </button>
</mat-dialog-actions>
