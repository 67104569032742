<div class="center-container">
  <h2 class="mat-headline-6">{{ "CREATE_BUSINESS_ACCOUNT" | transloco }}</h2>
  <div class="layout-column" ngModelGroup="billingDetailsForm" #billingDetailsForm="ngModelGroup">
    <h3>{{ "BILLING_CONTACT" | transloco }}</h3>
    @if (userTitles) {
      <mat-form-field appSelect [appSelectLabel]="'SALUTATION' | transloco">
        <mat-select
          #title="ngModel"
          [(ngModel)]="newClient.createClientRequest.billingDetails.title"
          [required]="newClient.accountType === AccountType.INDIVIDUAL"
          name="title">
          @for (item of userTitles | keyvalue; track item) {
            <mat-option [value]="item.key">{{ item.value }}</mat-option>
          }
        </mat-select>
        @if (title.hasError("required")) {
          <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
        }
      </mat-form-field>
    }
    <app-form-field>
      <app-input-label>{{ "FIRST_NAME" | transloco }}</app-input-label>
      <input
        #firstName="ngModel"
        [(ngModel)]="newClient.createClientRequest.billingDetails.firstName"
        [required]="newClient.accountType === AccountType.INDIVIDUAL"
        appInput
        name="firstName" />
      @if (firstName.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "MIDDLE_NAME" | transloco }}</app-input-label>
      <input [(ngModel)]="newClient.createClientRequest.billingDetails.middleName" appInput name="middleName" />
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "LAST_NAME" | transloco }}</app-input-label>
      <input
        #lastName="ngModel"
        [(ngModel)]="newClient.createClientRequest.billingDetails.lastName"
        [required]="newClient.accountType === AccountType.INDIVIDUAL"
        appInput
        name="lastName" />
      @if (lastName.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "PHONE" | transloco }}</app-input-label>
      <input
        #phone="ngModel"
        [(ngModel)]="newClient.createClientRequest.billingDetails.phone"
        [pattern]="PHONE_REGEX"
        required
        appInput
        name="phone" />
      @if (phone.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      } @else if (phone.hasError("pattern")) {
        <app-input-error>{{ "VALIDATION_ERROR_INVALID_PHONE_NUMBER" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "EMAIL_ADDRESS" | transloco }}</app-input-label>
      <input
        #email="ngModel"
        [(ngModel)]="newClient.createClientRequest.billingDetails.email"
        [pattern]="EMAIL_REGEX"
        required
        appInput
        type="email"
        name="email" />
      @if (email.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      } @else if (email.hasError("pattern")) {
        <app-input-error>{{ "VALIDATION_ERROR_INVALID_EMAIL_ADDRESS" | transloco }}</app-input-error>
      }
    </app-form-field>
    <h3>{{ "BILLING_ADDRESS" | transloco }}</h3>
    <app-form-field [class.display-none]="newClient.accountType === AccountType.INDIVIDUAL">
      <app-input-label>{{ "COMPANY_NAME" | transloco }}</app-input-label>
      <input
        #company="ngModel"
        [(ngModel)]="newClient.createClientRequest.companyName"
        [required]="newClient.accountType === AccountType.COMPANY"
        appInput
        name="company"
        required />
      @if (company.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "COMPANY_ADDRESS" | transloco }}</app-input-label>
      <input #street="ngModel" [(ngModel)]="newClient.createClientRequest.billingDetails.street" appInput name="street" required />
      @if (street.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "COMPANY_DEPARTMENT" | transloco }}</app-input-label>
      <input [(ngModel)]="newClient.createClientRequest.billingDetails.addition" appInput name="addition" />
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "POSTAL_CODE" | transloco }}</app-input-label>
      <input #zipCode="ngModel" [(ngModel)]="newClient.createClientRequest.billingDetails.zipCode" appInput name="zip" required />
      @if (zipCode.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "CITY" | transloco }}</app-input-label>
      <input #city="ngModel" [(ngModel)]="newClient.createClientRequest.billingDetails.city" appInput name="city" required />
      @if (city.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    <mat-form-field appSelect [appSelectLabel]="'COUNTRY' | transloco">
      <mat-select #country="ngModel" [(ngModel)]="newClient.createClientRequest.billingDetails.country" name="country" required>
        @for (country of countries; track country) {
          <mat-option [value]="country.id">{{ country.label }}</mat-option>
        }
      </mat-select>
      @if (country.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </mat-form-field>
  </div>
  <div class="action-buttons">
    <button (click)="stepChanged.emit(1)" mat-flat-button>
      <span>{{ "BACK" | transloco }}</span>
    </button>
    <button [disabled]="billingDetailsForm.invalid" color="primary" (click)="stepChanged.emit(3)" mat-flat-button>
      <span>{{ "NEXT" | transloco }}</span>
    </button>
  </div>
</div>
