import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule, TranslocoService} from '@jsverse/transloco';
import {ReplaceableDeviceTO} from 'api/entities';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {DeviceActivationEntity} from '../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {IoTService} from '../../../core/services/iot.service';

@Component({
  selector: 'app-replace-device-confirm',
  templateUrl: './replace-device-confirm.component.html',
  styleUrls: ['./replace-device-confirm.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class ReplaceDeviceConfirmComponent implements OnInit {
  loading: Subscription;
  device: ReplaceableDeviceTO;
  private locationId: number;
  private activationCode: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceActivationEntity: DeviceActivationEntity,
    private iotService: IoTService,
    private toastrService: ToastrService,
    private translate: TranslocoService
  ) {}

  ngOnInit() {
    this.activationCode = this.route.snapshot.paramMap.get('activationCode');
    const clientId = +this.route.snapshot.paramMap.get('clientId');
    this.locationId = +this.route.snapshot.paramMap.get('locationId');
    if (this.activationCode) {
      this.loading = this.deviceActivationEntity
        .getDeviceList(clientId, this.activationCode, false)
        .subscribe(response => (this.device = response.find(device => device.id === this.locationId)));
    }
  }

  replaceDevice() {
    this.loading = this.deviceActivationEntity
      .replaceDevice(this.activationCode, this.locationId)
      .pipe(
        switchMap(response => {
          if (response.restartRequired) {
            this.toastrService.info(this.translate.translate('DEVICE_RESTART_REQUIRED'), this.translate.translate('DEVICE_RESTART'), {
              disableTimeOut: true
            });
          }
          const newDevice = this.iotService.getDeviceByActivationCode(this.activationCode);
          if (newDevice) {
            this.iotService.removeDevice(newDevice);
          }
          const oldDevice = this.iotService.getDeviceByID(this.locationId);
          if (oldDevice) {
            this.iotService.removeDevice(oldDevice);
          }
          return this.iotService.registerDevice(this.activationCode, false);
        })
      )
      .subscribe(device => {
        if (device) {
          this.router.navigate(['device', device.activationCode, 'info']);
        } else {
          this.router.navigate(['/']);
        }
      });
  }
}
