import {Directive, ElementRef, OnInit, Optional} from '@angular/core';
import {FormControlName, NgModel} from '@angular/forms';

@Directive({
  selector: '[appInput]',
  standalone: true
})
export class AppInputDirective implements OnInit {
  formControl: FormControlName | NgModel;

  constructor(public elementRef: ElementRef<HTMLInputElement>, @Optional() formControlName: FormControlName, @Optional() ngModel: NgModel) {
    this.formControl = formControlName || ngModel;
  }

  ngOnInit(): void {
    if (this.elementRef.nativeElement.type === 'number') {
      this.elementRef.nativeElement.style.textAlign = 'right';
    }
  }
}
