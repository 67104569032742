import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {CredentialsI} from '../../../../../../alcedo/src/app/shared/entities/auth/auth.interface';
import {AuthService} from '../../../core/auth.service';
import {IoTService} from '../../../core/services/iot.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class LoginComponent {
  credentials: CredentialsI = {
    name: '',
    password: '',
    stayLoggedIn: true
  };
  loading$: Subscription;
  activation = false;

  constructor(
    private authService: AuthService,
    private iotService: IoTService,
    private route: ActivatedRoute,
    private router: Router,
    private oidcSecurityService: OidcSecurityService
  ) {
    this.activation = !!this.route.snapshot.params.activationCode;
    this.oidcSecurityService
      .isAuthenticated()
      .pipe(
        take(1),
        filter(isAuthenticated => isAuthenticated)
      )
      .subscribe(() => {
        router.navigate(['../confirmClient'], {relativeTo: route});
      });
  }

  goBack($event) {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      this.iotService.goToDeviceHub();
    }
    $event.preventDefault();
  }
}
