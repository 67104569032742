<div class="center-container">
  <h2 class="mat-headline-6">{{ "PLEASE_CONFIRM" | transloco }}</h2>
  <span [innerHTML]="'REPLACE_THIS_DEVICE' | transloco: {deviceName: device?.name}"></span>
  <br />
  <span>{{ "WARNING_REPLACE_MEASUREMENTS" | transloco }}</span>
  <br />
  <div class="action-buttons ok-cancel">
    <button (click)="replaceDevice()" class="ok warning" color="primary" mat-flat-button>
      <span>{{ "REPLACE" | transloco }}</span>
    </button>
    <button mat-flat-button class="cancel" routerLink="../../../replace">
      <span>{{ "DO_NOT_REPLACE" | transloco }}</span>
    </button>
  </div>
</div>
