import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {IoTService} from '../../core/services/iot.service';

@Component({
  selector: 'app-no-account-info',
  templateUrl: './no-account-info.component.html',
  styleUrls: ['./no-account-info.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class NoAccountInfoComponent {
  constructor(private iotService: IoTService) {}

  goBack($event) {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      this.iotService.goToDeviceHub();
    }
    $event.preventDefault();
  }
}
