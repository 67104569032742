<div class="center-container">
  <form (ngSubmit)="sendEmailAgain()" [formGroup]="resendEmailForm" name="resendEmailForm">
    <h1>{{ "RESEND_EMAIL" | transloco }}</h1>
    <p>{{ "DEVICE_REGISTRATION_RESEND_EMAIL_INFORMATION" | transloco }}</p>
    <p>{{ "DEVICE_REGISTRATION_RESEND_EMAIL_WARNING" | transloco }}</p>
    <app-form-field>
      <app-input-label>{{ "EMAIL_ADDRESS" | transloco }}</app-input-label>
      <input autocomplete="email" formControlName="email" appInput name="email" required type="email" />
      @if (resendEmailForm.get("email").hasError("required")) {
        <app-input-error>
          {{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}
        </app-input-error>
      }
      @if (resendEmailForm.get("email").hasError("pattern")) {
        <app-input-error>
          {{ "VALIDATION_ERROR_INVALID_EMAIL_ADDRESS" | transloco }}
        </app-input-error>
      }
    </app-form-field>
    <div class="action-buttons">
      <button color="primary" mat-flat-button type="submit">
        <span>{{ "SEND" | transloco }}</span>
      </button>
    </div>
    @if (emailWasSent) {
      <alc-message class="info" icon="info">
        <span>{{ "DEVICE_REGISTRATION_LINK_RESENT" | transloco }}</span>
      </alc-message>
    }
  </form>
  <ng-container [cgBusy]="loading$"></ng-container>
</div>
