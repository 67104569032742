import {inject} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {AuthService} from './auth.service';

export function userCanActivateGuard(): Observable<boolean> {
  const auth = inject(AuthService);
  const oidcSecurityService = inject(OidcSecurityService);
  return forkJoin([auth.setLocale(navigator.language), oidcSecurityService.isAuthenticated()]).pipe(
    take(1),
    switchMap(([lang, isAuthenticated]) => (isAuthenticated ? auth.loadCurrentUser() : of(true))),
    map(() => true)
  );
}
