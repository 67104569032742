<div class="center-container">
  @if (activationCode) {
    <div>
      <h1>{{ "DEVICE_REGISTRATION_CONFIRM_CLIENT" | transloco }}</h1>
      <p>{{ "DEVICE_REGISTRATION_INBOX_EMAIL" | transloco }}</p>
      <div class="svg">
        <svg width="100%" height="100%" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve">
          <clipPath id="clip">
            <circle cx="150" cy="150" r="150" />
          </clipPath>
          <g clip-path="url(#clip)">
            <circle class="border" cx="150" cy="150" r="150" />
            <path
              class="email"
              d="M182.455,151.708c0.093,0.237 0.217,0.465 0.373,0.677l46.946,53.555c-0.942,0.358 -1.963,0.554 -3.031,0.554l-154.127,0c-1.067,0 -2.089,-0.196 -3.031,-0.554l47.437,-54.115l3.049,3.05c16.51,16.405 43.879,15.24 59.217,0l3.167,-3.167Zm-117.766,-52.216l48.097,48.097c-0.049,0.043 -0.098,0.087 -0.146,0.133l-47.562,54.257c-0.645,-1.202 -1.011,-2.576 -1.011,-4.035l0,-95.247c0,-1.133 0.22,-2.215 0.622,-3.205Zm169.981,0c0.402,0.99 0.623,2.072 0.623,3.205l0,95.247c0,1.459 -0.367,2.833 -1.012,4.035l-46.942,-53.549c-0.286,-0.274 -0.597,-0.506 -0.928,-0.679l48.259,-48.259Zm-7.927,-5.345c1.39,0 2.704,0.333 3.864,0.923l-55.556,55.556c-13.923,13.845 -37.357,13.133 -50.572,0.169c-18.638,-18.515 -37.186,-37.121 -55.744,-55.717c1.165,-0.595 2.484,-0.931 3.881,-0.931l154.127,0Z" />
          </g>
        </svg>
      </div>
      <p>{{ "DEVICE_REGISTRATION_ALMOST_DONE" | transloco }}</p>
      @if (resendEmail) {
        <alc-message class="prompt" icon="info">
          <span>{{ "DEVICE_REGISTRATION_PENDING_ACTIVATION_REQUEST_FOUND" | transloco }}</span>
        </alc-message>
      }
      <div class="action-buttons">
        <a routerLink="../resend-email">
          <span>{{ "RESEND_EMAIL" | transloco }}</span>
        </a>
      </div>
    </div>
  }
</div>
