<div class="center-container">
  <h2 class="mat-headline-6">{{ "CREATE_ACCOUNT" | transloco }}</h2>
  <div class="layout-column" ngModelGroup="userForm" #userForm="ngModelGroup">
    @if (userTitles) {
      <mat-form-field appSelect [appSelectLabel]="'SALUTATION' | transloco">
        <mat-select #title="ngModel" [(ngModel)]="newClient.createClientRequest.user.title" required name="title">
          @for (item of userTitles | keyvalue; track item) {
            <mat-option [value]="item.key">{{ item.value }}</mat-option>
          }
        </mat-select>
        @if (title.hasError("required")) {
          <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
        }
      </mat-form-field>
    }
    <app-form-field>
      <app-input-label>{{ "FIRST_NAME" | transloco }}</app-input-label>
      <input
        #firstName="ngModel"
        [(ngModel)]="newClient.createClientRequest.user.firstName"
        [value]="newClient.firstName"
        appInput
        name="firstName"
        required />
      @if (firstName.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "LAST_NAME" | transloco }}</app-input-label>
      <input
        #lastName="ngModel"
        [(ngModel)]="newClient.createClientRequest.user.lastName"
        [value]="newClient.lastName"
        appInput
        name="lastName"
        required />
      @if (lastName.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    @if (UserLocalesLabels) {
      <mat-form-field appSelect [appSelectLabel]="'LANGUAGE_AND_REGION' | transloco">
        <mat-select #language="ngModel" [(ngModel)]="newClient.createClientRequest.user.language" name="language" required>
          @for (item of UserLocalesLabels | keyvalue; track item) {
            <mat-option [value]="item.key">{{ item.value }}</mat-option>
          }
        </mat-select>
        @if (language.hasError("required")) {
          <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
        }
      </mat-form-field>
    }
    <app-form-field>
      <app-input-label>{{ "PHONE" | transloco }}</app-input-label>
      <input #phone="ngModel" [(ngModel)]="newClient.createClientRequest.user.phone" [pattern]="PHONE_REGEX" appInput name="phone" required />
      @if (phone.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      } @else if (phone.hasError("pattern")) {
        <app-input-error>{{ "VALIDATION_ERROR_INVALID_PHONE_NUMBER" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "LOGIN_EMAIL" | transloco }}</app-input-label>
      <input
        #email="ngModel"
        [(ngModel)]="newClient.createClientRequest.user.email"
        [pattern]="EMAIL_REGEX"
        appInput
        name="email"
        required
        type="email" />
      @if (email.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      } @else if (email.hasError("pattern")) {
        <app-input-error>{{ "VALIDATION_ERROR_INVALID_EMAIL_ADDRESS" | transloco }}</app-input-error>
      }
    </app-form-field>
    <app-form-field>
      <app-input-label>{{ "PASSWORD" | transloco }}</app-input-label>
      <input
        #passwordInput
        #newPassword="ngModel"
        [(ngModel)]="newClient.createClientRequest.user.password"
        (ngModelChange)="validatePasswordConfirmation()"
        [pattern]="PASSWORD_REGEX"
        required
        type="password"
        name="password"
        appInput />
      @if (newPassword.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      } @else if (newPassword.hasError("pattern")) {
        <app-input-error>{{ "VALIDATION_ERROR_PASSWORD_STRENGTH" | transloco }}</app-input-error>
      }
      <app-show-password [passwordInput]="passwordInput" appInputSuffix />
    </app-form-field>

    @if (hadInvalidPassword) {
      <button (click)="suggestRandomPassword(passwordInput)" mat-flat-button class="suggest-password">
        <span>{{ "SUGGEST_PASSWORD" | transloco }}</span>
      </button>
    }

    <app-form-field>
      <app-input-label>{{ "CONFIRM_PASSWORD" | transloco }}</app-input-label>
      <input
        ngModel
        #confirmPasswordInput
        #confirmPassword="ngModel"
        (ngModelChange)="validatePasswordConfirmation()"
        required
        appInput
        type="password"
        name="confirmPassword" />
      @if (confirmPassword.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      } @else if (confirmPassword.hasError("mismatch")) {
        <app-input-error>{{ "VALIDATION_ERROR_PASSWORDS_DO_NOT_MATCH" | transloco }}</app-input-error>
      }
      <app-show-password [passwordInput]="confirmPasswordInput" appInputSuffix />
    </app-form-field>
  </div>
  <div class="action-buttons">
    <button (click)="hasPaidLicenses ? stepChanged.emit(2) : stepChanged.emit(1)" mat-flat-button>
      <span>{{ "BACK" | transloco }}</span>
    </button>
    <button [disabled]="userForm.invalid" (click)="displayUserLicense()" color="primary" mat-flat-button>
      <span>{{ "SIGN_UP" | transloco }}</span>
    </button>
  </div>
</div>
