import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule, TranslocoService} from '@jsverse/transloco';
import {RealEstateInVicinityTO, RealEstateProductActivationTO} from 'api/entities';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {MessageComponent} from '../../../../../../../alcedo/src/app/shared/components/message/message.component';
import {DeviceActivationEntity} from '../../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {JSONUtils} from '../../../../../../../alcedo/src/app/shared/services/json-utils.service';
import {orderByLocale} from '../../../../../../../alcedo/src/app/shared/services/order-by-locale.service';
import {DeviceService} from '../../../../core/services/device.service';
import {AppSelectDirective} from '../../../shared/app-select/app-select.directive';

@Component({
  selector: 'app-device-real-estate-select',
  templateUrl: './device-real-estate-select.component.html',
  styleUrls: ['./device-real-estate-select.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MessageComponent,
    MatFormFieldModule,
    AppSelectDirective,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    TranslocoModule
  ]
})
export class DeviceRealEstateSelectComponent implements OnInit {
  canUseGPS = !!navigator.geolocation;
  loading: Subscription;
  activationCode: string;
  clientId: number;
  deviceData: RealEstateProductActivationTO = {
    name: '',
    location: '',
    latitude: null,
    longitude: null,
    deviceActivationInfo: {
      deviceName: '',
      activationCode: '',
      publicAccess: true
    }
  };
  private allRealEstates: RealEstateInVicinityTO[];
  private filteredRealEstates: RealEstateInVicinityTO[];
  showFilteredRealEstates: boolean;
  private realEstates: RealEstateInVicinityTO[];
  realEstatesLabels: ({label?: string} & RealEstateInVicinityTO)[];
  selectedRealEstate: RealEstateInVicinityTO;

  constructor(
    private deviceActivationEntity: DeviceActivationEntity,
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private router: Router,
    private toastrService: ToastrService,
    private translate: TranslocoService
  ) {}

  ngOnInit() {
    this.activationCode = this.route.snapshot.paramMap.get('activationCode');
    this.showFilteredRealEstates = false;

    this.loading = this.deviceService
      .getDevice(this.activationCode)
      .pipe(
        switchMap(device => {
          this.clientId = device.clientId;
          this.deviceData.deviceActivationInfo.deviceName = device.name;
          this.deviceData.deviceActivationInfo.activationCode = this.activationCode;
          return this.deviceActivationEntity.getProximityRealEstates(this.clientId, this.deviceData.deviceActivationInfo.activationCode);
        })
      )
      .subscribe((response: RealEstateInVicinityTO[]) => {
        this.allRealEstates = response;
        this.realEstates = this.allRealEstates;
        this.setRealEstateLabels();

        if (this.realEstates.length) {
          this.selectedRealEstate = this.realEstates[0];
        }
        this.onRealEstateChange();
      });
  }

  onRealEstateChange() {
    if (this.selectedRealEstate && this.selectedRealEstate.id) {
      this.deviceData.id = this.selectedRealEstate.id;
      this.deviceData.name = null;
    } else {
      this.deviceData.id = this.deviceData.name = null;
    }
  }

  handleGPSClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => this.onGetGeoLocationSuccess(position),
        () => (this.canUseGPS = false)
      );
    } else {
      this.canUseGPS = false;
    }
  }

  clearGPS() {
    this.realEstates = this.allRealEstates;
    this.showFilteredRealEstates = false;
    this.setRealEstateLabels();
    this.selectRealEstate();
  }

  createOrUpdateRealEstate() {
    this.loading = this.deviceActivationEntity.createOrUpdateRealEstate(this.clientId, this.deviceData).subscribe(() => {
      this.router.navigate(['../alerting-ask'], {relativeTo: this.route});
    });
  }

  private onGetGeoLocationSuccess(position) {
    if (position) {
      this.realEstates = [];
      this.loading = this.deviceActivationEntity
        .getProximityRealEstates(
          this.clientId,
          this.deviceData.deviceActivationInfo.activationCode,
          position.coords.latitude,
          position.coords.longitude
        )
        .subscribe(response => this.onGetRealEstatesSuccess(response));
    }
  }

  private onGetRealEstatesSuccess(response) {
    this.filteredRealEstates = response;
    this.realEstates = this.filteredRealEstates;
    this.showFilteredRealEstates = true;
    this.setRealEstateLabels();
    this.selectRealEstate();
    this.onRealEstateChange();

    if (this.realEstates.length) {
      this.toastrService.info(this.translate.translate('REAL_ESTATES_FOUND_NEARBY'));
    } else {
      this.toastrService.warning(this.translate.translate('NO_REAL_ESTATES_NEARBY'));
    }
  }

  private selectRealEstate() {
    if (this.selectedRealEstate && this.selectedRealEstate.id) {
      this.selectedRealEstate = this.realEstates.find(realEstate => realEstate.id === this.selectedRealEstate.id);
    }

    if (!this.selectedRealEstate && this.realEstates.length) {
      this.selectedRealEstate = this.realEstates[0];
    }
  }

  private setRealEstateLabels() {
    this.realEstatesLabels = JSONUtils.clone(this.realEstates);
    this.realEstatesLabels.forEach(realEstate => (realEstate.label = (realEstate.parentName ? realEstate.parentName + ', ' : '') + realEstate.name));
    this.realEstatesLabels = orderByLocale(this.realEstatesLabels, 'label');
  }
}
