import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {apiVersion} from '../../../api-version.constant';
import {Alcedo7User} from '../user/avelon-user.service';

@Injectable({providedIn: 'root'})
export class AuthEntity {
  constructor(private http: HttpClient) {}

  getLicense(): Observable<string> {
    return this.http.get(`${apiVersion}license`, {responseType: 'text'});
  }

  getLicenseForLanguage(language: string): Observable<string> {
    return this.http.get(`${apiVersion}licenses?language=${language.toLowerCase()}&format=html`, {responseType: 'text'});
  }

  getExceededLicenses() {
    return this.http.get<any>(`${apiVersion}clients/${Alcedo7User.selectedClient.id}/devices/exceededLicenses`);
  }
}
