<form (ngSubmit)="createOrUpdateRealEstate()" class="center-container">
  <h2 class="mat-headline-6">{{ "CREATE_NEW_REAL_ESTATE" | transloco }}</h2>
  @if (canUseGPS === false) {
    <alc-message class="info" icon="info">
      <span>{{ "CANNOT_ACCESS_GPS" | transloco }}</span>
      <span>{{ "SPECIFY_LOCATION_MANUALLY" | transloco }}</span>
    </alc-message>
  }
  <app-form-field>
    <app-input-label>{{ "ADDRESS" | transloco }}</app-input-label>
    <input name="location" [(ngModel)]="deviceData.location" (change)="changeAddress()" appInput />
  </app-form-field>
  <app-form-field>
    <app-input-label>{{ "GPS_COORDINATES" | transloco }}</app-input-label>
    <input name="gps" appInput #gpsModel="ngModel" [(ngModel)]="gps" (ngModelChange)="onGpsChange()" pattern="^(-?\d+(\.\d+)?),\s(-?\d+(\.\d+)?)$" />
    @if (gpsModel.hasError("pattern")) {
      <app-input-error>47.3948, 8.4839</app-input-error>
    }
  </app-form-field>
  <div class="action-buttons">
    <button (click)="handleGPSClick()" class="gps-location" mat-flat-button type="button">
      <mat-icon>gps_fixed</mat-icon>
      <span>{{ "USE_MY_GPS" | transloco }}</span>
    </button>
  </div>
  <app-form-field class="real-test-name-input">
    <app-input-label>{{ "REAL_ESTATE_NAME" | transloco }}</app-input-label>
    <input name="name" appInput #realEstateNameModel="ngModel" [(ngModel)]="deviceData.name" (change)="changeRealEstateName()" required />
    @if (realEstateNameModel.hasError("required")) {
      <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
    }
  </app-form-field>
  <div class="action-buttons">
    <button mat-flat-button type="submit" [disabled]="!deviceData.name" class="mat-primary">
      <span>{{ "OK" | transloco }}</span>
    </button>
  </div>
  <div class="action-buttons">
    @if (!hideSelectExistingRealEstate) {
      <a routerLink="../real-estate-select">
        <span>{{ "SELECT_EXISTING_REAL_ESTATE" | transloco }}</span>
      </a>
    }
    <a routerLink="../alerting-ask">
      <span>{{ "SKIP" | transloco }}</span>
    </a>
  </div>
</form>
