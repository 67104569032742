import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {TranslocoModule} from '@jsverse/transloco';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslocoModule]
})
export class LicenseComponent {
  disableAcceptButton = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {licenseText: string; enforceLicenseAccept: boolean},
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  validateReadLicense(event): void {
    if (event.target.scrollTop + event.target.offsetHeight + 1 >= event.target.scrollHeight * 0.99) {
      this.disableAcceptButton = false;
      this.changeDetectionRef.detectChanges();
    }
  }
}
