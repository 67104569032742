import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {PlatformDetector} from '../../../../../../alcedo/src/app/shared/services/platform-detector.service';
import {BrandService} from '../../../core/brand.service';
import {SystemService} from '../../../core/system.service';

@Component({
  selector: 'app-alerting-complete',
  templateUrl: './alerting-complete.component.html',
  styleUrls: ['./alerting-complete.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class AlertingCompleteComponent implements OnInit {
  alcedoURL: string;
  platformTitle: string;
  iOS = PlatformDetector.isIOS();
  android = PlatformDetector.isAndroid();

  constructor(private systemService: SystemService) {}

  ngOnInit() {
    this.systemService.getURLs().subscribe(urls => {
      this.alcedoURL = urls.main;
    });

    this.platformTitle = BrandService.getPlatformTitle();
  }

  goToAvelon() {
    window.open(this.alcedoURL, '_blank', 'noreferrer');
  }
}
