<div class="center-container">
  <form #emailConfirmationForm="ngForm" (ngSubmit)="clientActivate()" name="emailConfirmationForm">
    <h2 class="mat-headline-6">{{ "ACTIVATE_ACCOUNT" | transloco }}</h2>
    <app-form-field>
      <app-input-label>{{ "DEVICE_REGISTRATION_CODE_FROM_EMAIL" | transloco }}</app-input-label>
      <input #clientActivationCodeModel="ngModel" [(ngModel)]="clientActivationCode" autofocus appInput name="clientActivationCode" required />
      @if (clientActivationCodeModel.hasError("required")) {
        <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
      }
    </app-form-field>
    <div class="action-buttons">
      <button [disabled]="!emailConfirmationForm.valid" color="primary" mat-flat-button type="submit">
        <span>{{ "ACTIVATE_ACCOUNT" | transloco }}</span>
      </button>
    </div>
  </form>
  <ng-container [cgBusy]="loading$"></ng-container>
</div>
