import {CountryEnum, Currency, UserTitle} from 'api/entities';
import {GeoDecodeGoogleService, NewClient} from '../../shared/entities/devices/device.interface';

export enum AccountType {
  INDIVIDUAL = 'Individual',
  COMPANY = 'Company'
}

export function initNewClient(): NewClient {
  return {
    activationCode: '',
    accountType: AccountType.COMPANY,
    createClientRequest: {
      billingDetails: {
        addition: '',
        city: '',
        country: CountryEnum.CH,
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        street: '',
        title: UserTitle.NONE,
        zipCode: ''
      },
      companyName: '',
      currency: Currency.EUR,
      user: {
        title: UserTitle.NONE,
        firstName: '',
        lastName: '',
        email: '',
        licenseAccepted: false,
        language: null,
        password: ''
      }
    },
    latitude: null,
    longitude: null
  };
}

export function getGPSCoordinatesSuccess(response: GeoDecodeGoogleService): {latitude: number; longitude: number} {
  const result = response.results[0];
  if (!result?.geometry?.location) {
    return {latitude: null, longitude: null};
  }
  const latLng = result.geometry.location;
  const lat = +latLng.lat.toFixed(6);
  const lng = +latLng.lng.toFixed(6);
  return {latitude: lat, longitude: lng};
}
