<mat-sidenav-container class="layout-column layout-fill">
  <mat-sidenav #sidenav fixedInViewport="true" mode="over">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button (click)="sidenav.toggle()" mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="flex"></div>
        <div class="logo-image"></div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div>
      <div (click)="sidenav.toggle()" [routerLink]="['/']" class="menu-item">
        <mat-icon>devices</mat-icon>
        <span>{{ "MY_DEVICES" | transloco }}</span>
      </div>
      <div (click)="sidenav.toggle()" [routerLink]="['/ar']" class="menu-item">
        <mat-icon>videocam</mat-icon>
        <span>{{ "AUGMENTED_REALITY_CAMERA" | transloco }}</span>
      </div>
      <div (click)="sidenav.toggle()" [routerLink]="['/quiz']" class="menu-item">
        <mat-icon>casino</mat-icon>
        <span>{{ "ENERGY_QUIZ" | transloco }}</span>
      </div>
      <mat-divider></mat-divider>
      <div (click)="sidenav.toggle()" [routerLink]="['/scan']" class="menu-item">
        <mat-icon>add_circle</mat-icon>
        <span>{{ "SCAN_DEVICE" | transloco }}</span>
      </div>
      <mat-divider></mat-divider>
      <div (click)="alcedoURL ? goToAvelon() : null; sidenav.toggle()" class="menu-item">
        <mat-icon>open_in_new</mat-icon>
        <span>Avelon</span>
      </div>
      @if (Alcedo7User.currentUser) {
        <div (click)="logOut(); sidenav.toggle()" class="menu-item">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ "SIGN_OUT" | transloco }}</span>
        </div>
      } @else {
        <div (click)="signIn(); sidenav.toggle()" class="menu-item">
          <mat-icon>vpn_key</mat-icon>
          <span>{{ "SIGN_IN" | transloco }}</span>
        </div>
        <div (click)="goToNoAccountInfo(); sidenav.toggle()" class="menu-item">
          <mat-icon>no_accounts</mat-icon>
          <span>{{ "NO_ACCOUNT" | transloco }}</span>
        </div>
      }
    </div>
    <div class="flex"></div>
    <div class="version">{{ "VERSION" | transloco: {version: version} }}</div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="app-toolbar mat-elevation-z2" color="primary">
      <button (click)="sidenav.toggle()" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      @if (!isWagoDomain) {
        <span class="app-title flex">Avelon IoT</span>
      }
      @if (Alcedo7User.currentUser && Alcedo7User.clients?.length > 1) {
        <div class="client-selection-wrapper">
          <mat-select (ngModelChange)="clientChange($event)" [ngModel]="selectedClientId || Alcedo7User.currentUser.clientId">
            @for (client of Alcedo7User.clients; track client) {
              <mat-option [value]="client.id">{{ client.name }}</mat-option>
            }
          </mat-select>
        </div>
      }
    </mat-toolbar>
    <div [@routeAnimation]="prepareRoute()" class="router-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
