export class PlatformDetector {
  static iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'MacIntel'];

  static isIOS(): boolean {
    return window.navigator.platform && PlatformDetector.iDevices.indexOf(window.navigator.platform) > -1;
  }

  static isIOSBelow13(): boolean {
    // https://caniuse.com/#feat=mdn-api_visualviewport
    return PlatformDetector.isIOS() && !(window as any).visualViewport;
  }

  static isAndroid(): boolean {
    return /android/i.test(navigator.userAgent);
  }

  static isTouchEnabled() {
    // first condition is for native touch devices like Android, iOS
    // second is for windows Edge and hybrid devices like laptop with touch
    // extra if there is a case where they don't work add Boolean(window.TouchEvent)
    return 'ontouchstart' in window || navigator.maxTouchPoints > 2;
  }

  static getBasePath(): string {
    return location.pathname + location.search;
  }

  static isBrowserFirefox(): boolean {
    // https://caniuse.com/?search=mozSetImageElement
    return !!(document as any).mozSetImageElement;
  }

  static isChrome(): boolean {
    return !!(window as any).chrome;
  }
}
