import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';

@Component({
  selector: 'app-alerting-ask',
  templateUrl: './alerting-ask.component.html',
  styleUrls: ['./alerting-ask.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class AlertingAskComponent {
  activationCode: string;

  constructor(private route: ActivatedRoute) {
    this.activationCode = this.route.snapshot.params.activationCode;
  }
}
