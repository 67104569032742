import {KeyValuePipe} from '@angular/common';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlContainer, FormsModule, NgForm} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {CountryTO} from 'api/entities';
import {Subscription} from 'rxjs/internal/Subscription';
import {AccountType} from '../../../../../../alcedo/src/app/access-control/sign-up/sign-up.static';
import {MessageComponent} from '../../../../../../alcedo/src/app/shared/components/message/message.component';
import {EMAIL_REGEX, PHONE_REGEX} from '../../../../../../alcedo/src/app/shared/constants/regular-expressions.constant';
import {unsubscribe} from '../../../../../../alcedo/src/app/shared/decorators/unsubscribe.decorator';
import {DeviceActivationEntity} from '../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {NewClient} from '../../../../../../alcedo/src/app/shared/entities/devices/device.interface';
import {orderByLocale} from '../../../../../../alcedo/src/app/shared/services/order-by-locale.service';
import {AppFormFieldComponent} from '../../shared/app-form-field/app-form-field.component';
import {AppInputErrorComponent} from '../../shared/app-form-field/app-input-error.component';
import {AppInputLabelComponent} from '../../shared/app-form-field/app-input-label.component';
import {AppInputDirective} from '../../shared/app-form-field/app-input.directive';
import {AppSelectDirective} from '../../shared/app-select/app-select.directive';
import {SignUpSteps} from '../sign-up-ask/sign-up-ask.component';

@Component({
  selector: 'app-sign-up-business',
  templateUrl: './sign-up-business.component.html',
  styleUrls: ['./sign-up-business.component.scss'],
  standalone: true,
  imports: [
    AppFormFieldComponent,
    AppInputLabelComponent,
    AppInputDirective,
    AppInputErrorComponent,
    MatFormFieldModule,
    AppSelectDirective,
    MatSelectModule,
    MatOptionModule,
    MessageComponent,
    MatButtonModule,
    TranslocoModule,
    RouterLink,
    FormsModule,
    MatInput,
    KeyValuePipe
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SignUpBusinessComponent implements OnInit, OnDestroy {
  @Input() newClient: NewClient;
  @Input() userTitles: Record<string, string>;
  @Output() stepChanged = new EventEmitter<SignUpSteps>();
  countries: CountryTO[];
  readonly PHONE_REGEX = PHONE_REGEX;
  readonly EMAIL_REGEX = EMAIL_REGEX;
  readonly AccountType = AccountType;
  private countries$: Subscription;

  constructor(private deviceActivationEntity: DeviceActivationEntity) {}

  ngOnInit(): void {
    this.countries$ = this.deviceActivationEntity.getCountries().subscribe(response => (this.countries = orderByLocale(response, 'label')));
  }

  ngOnDestroy(): void {
    unsubscribe(this);
  }
}
