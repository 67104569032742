import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslocoModule} from '@jsverse/transloco';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslocoModule]
})
export class ContactSupportComponent {}
