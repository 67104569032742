<div class="center-container">
  <h1>{{ "ACTIVATE_ACCOUNT_COMPLETE" | transloco }}</h1>
  <div class="svg">
    <svg width="100%" height="100%" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve">
      <clipPath id="clip">
        <circle cx="150" cy="150" r="150" />
      </clipPath>
      <g clip-path="url(#clip)">
        <circle class="border" cx="150" cy="150" r="150" />
        <path
          d="M62.32,123.341c-14.218,-4.514 -14.013,8.003 -28.582,3.078c-1.025,12.927 0.411,13.953 0.411,16.005c13.748,2.052 15.303,-9.234 28.171,-6.156c0.556,-5.129 0.47,-4.719 0,-12.927Z"
          class="filled-fragment"
        />
        <path
          d="M75.868,195.253c-7.312,12.792 -14.748,5.076 -22.06,18.364c-8.554,-7.092 -8.161,-8.729 -9.404,-9.97c8.472,-10.97 16.406,-5.245 23.634,-16.212c3.5,2.709 3.191,2.522 7.83,7.818Z"
          class="filled-fragment"
        />
        <path
          d="M109.314,81.421c-11.329,-19.813 -20.539,-11.33 -31.923,-31.828c-10.794,7.295 -10.079,9.671 -11.623,11.022c12.696,17.635 22.803,12.048 33.815,29.316c4.438,-2.718 4.04,-2.55 9.731,-8.51Z"
          class="filled-fragment"
        />
        <path
          d="M151.831,109.549c-0.612,-22.816 -12.739,-19.697 -13.074,-43.143c-12.961,1.323 -13.454,3.755 -15.454,4.215c2.846,21.543 14.393,21.4 15.929,41.823c5.196,-0.296 4.765,-0.336 12.599,-2.895Z"
          class="filled-fragment"
        />
        <circle cx="125.721" cy="127.396" r="3.616" class="line-fragment" />
        <circle cx="150.6" cy="164.587" r="3.616" class="line-fragment" />
        <circle cx="84.682" cy="138.169" r="3.616" class="line-fragment" />
        <circle cx="106.997" cy="45.318" r="3.616" class="line-fragment" />
        <circle cx="40.565" cy="184.686" r="3.616" class="line-fragment" />
        <path d="M122.105,194.515c-13.151,0.801 -24.023,1.202 -37.423,12.023" class="line-fragment" />
        <path d="M84.682,99.951c0,0 -6.587,-13.226 -36.645,-20.039" class="line-fragment" />
        <path d="M171.074,85.703c-2.004,-18.035 -0.401,-35.871 8.817,-49.396" class="line-fragment" />
        <path d="M148.938,23.021c0,0 3.718,12.273 3.309,26.572" class="line-fragment" />
        <path d="M48.037,165.066c0,0 -13.608,1.653 -22.691,12.282" class="line-fragment" />
        <path d="M171.4,124.033c0,0 -1.093,-13.665 -11.34,-23.177" class="line-fragment" />
        <path
          class="grab-bag"
          d="M134.338,185.719c-7.157,-7.157 -1.563,-24.37 12.483,-38.416c14.046,-14.046 31.26,-19.64 38.416,-12.484c0.956,0.957 1.685,2.093 2.196,3.377l92.516,142.234l-142.235,-92.515c-1.284,-0.511 -2.42,-1.24 -3.376,-2.196Z"
        />
      </g>
    </svg>
  </div>
  <p></p>
  <div class="action-buttons">
    <button color="primary" mat-flat-button routerLink="../change-name">
      <span>{{ "CONTINUE" | transloco }}</span>
    </button>
  </div>
</div>
