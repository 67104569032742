<div class="center-container">
  <h2 class="mat-headline-6">{{ "NO_ACCOUNT" | transloco }}</h2>
  <div class="explanation">
    <p [innerHTML]="'NO_ACCOUNT_HINT_1' | transloco"></p>
    <p [innerHTML]="'NO_ACCOUNT_HINT_2' | transloco"></p>
  </div>
  <div class="action-buttons">
    <button [routerLink]="['/scan']" mat-flat-button>
      <span>{{ "SCAN" | transloco }}</span>
    </button>
    <a href="#" (click)="goBack($event)">{{ "BACK" | transloco }}</a>
  </div>
</div>
