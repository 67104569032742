import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {RealEstateInVicinityTO} from 'api/entities';
import {switchMap} from 'rxjs/operators';
import {DeviceActivationEntity} from '../../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {DeviceService} from '../../../../core/services/device.service';

@Component({
  selector: 'app-device-real-estate-ask',
  templateUrl: './device-real-estate-ask.component.html',
  styleUrls: ['./device-real-estate-ask.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class DeviceRealEstateAskComponent implements OnInit {
  activationCode: string;

  constructor(
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private deviceActivationEntity: DeviceActivationEntity,
    private router: Router
  ) {}

  ngOnInit() {
    this.activationCode = this.route.snapshot.params.activationCode;

    this.deviceService
      .getDevice(this.activationCode)
      .pipe(
        switchMap(device => {
          return this.deviceActivationEntity.getProximityRealEstates(device.clientId, this.activationCode);
        })
      )
      .subscribe((response: RealEstateInVicinityTO[]) => {
        if (!response.length) {
          this.router.navigate(['../real-estate-add'], {relativeTo: this.route, replaceUrl: true});
        }
      });
  }
}
