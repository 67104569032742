import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {BrandService} from '../../../core/brand.service';
import {SystemService} from '../../../core/system.service';

@Component({
  selector: 'app-alerting-no-alarm-chain',
  templateUrl: './alerting-no-alarm-chain.component.html',
  styleUrls: ['./alerting-no-alarm-chain.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class AlertingNoAlarmChainComponent implements OnInit {
  alcedoURL: string;
  platformTitle: string;

  constructor(private systemService: SystemService) {}

  ngOnInit() {
    this.systemService.getURLs().subscribe(urls => {
      this.alcedoURL = urls.main;
    });

    this.platformTitle = BrandService.getPlatformTitle();
  }

  goToAvelon() {
    window.open(this.alcedoURL, '_blank', 'noreferrer');
  }
}
