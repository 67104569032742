<div class="center-container">
  <h2 class="mat-headline-6">{{ "WHERE_DEVICE_LOCATED" | transloco }}</h2>
  <p>{{ "REAL_ESTATE_HINT" | transloco }}</p>
  <div class="action-buttons">
    <button color="primary" mat-flat-button routerLink="../real-estate-add">
      <span>{{ "CREATE_NEW_REAL_ESTATE" | transloco }}</span>
    </button>
    <button mat-flat-button routerLink="../real-estate-select">
      <span>{{ "ADD_TO_EXISTING_REAL_ESTATE" | transloco }}</span>
    </button>
    <div class="layout-column layout-align-center-center">
      <a routerLink="../alerting-ask"><span>{{ "SKIP" | transloco }}</span></a>
    </div>
  </div>
</div>
