import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {CgBusyDirective} from 'angular-busy2';
import {Subscription} from 'rxjs';
import {MessageComponent} from '../../../../../../alcedo/src/app/shared/components/message/message.component';
import {EMAIL_REGEX} from '../../../../../../alcedo/src/app/shared/constants/regular-expressions.constant';
import {DeviceActivationEntity} from '../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {AppFormFieldComponent} from '../../shared/app-form-field/app-form-field.component';
import {AppInputErrorComponent} from '../../shared/app-form-field/app-input-error.component';
import {AppInputLabelComponent} from '../../shared/app-form-field/app-input-label.component';
import {AppInputDirective} from '../../shared/app-form-field/app-input.directive';
import {SignUpService} from '../sign-up-private/sign-up.service';

@Component({
  selector: 'app-sign-up-resend-email',
  templateUrl: './sign-up-resend-email.component.html',
  styleUrls: ['./sign-up-resend-email.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AppFormFieldComponent,
    AppInputLabelComponent,
    AppInputDirective,
    AppInputErrorComponent,
    MatButtonModule,
    MessageComponent,
    CgBusyDirective,
    TranslocoModule
  ]
})
export class SignUpResendEmailComponent implements OnInit {
  loading$: Subscription;
  activationCode: string;
  emailWasSent: boolean;
  email: string;

  resendEmailForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)])
  });

  constructor(
    private router: Router,
    private deviceActivationEntity: DeviceActivationEntity,
    private signUpService: SignUpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activationCode = this.route.snapshot.paramMap.get('activationCode');
    this.email = this.signUpService.emailUsed;
    this.resendEmailForm.setValue({email: this.email});
  }

  sendEmailAgain(): void {
    this.loading$ = this.deviceActivationEntity.resendEmail(this.activationCode, this.resendEmailForm.value.email).subscribe(() => {
      this.emailWasSent = true;
    });
  }
}
