import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatSelect} from '@angular/material/select';
import {Subscription} from 'rxjs';
import {APP_SELECT_DATA} from '../app-select.directive';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './app-select.component.html',
  styleUrls: ['./app-select.component.scss']
})
export class AppSelectComponent implements OnInit, OnDestroy {
  @ViewChild('errorContainer', {read: ElementRef, static: true}) errorContainer: ElementRef;
  selectedValue: string;
  private selectionChange$: Subscription;

  get showError(): boolean {
    return this.data.matSelect.ngControl?.touched && !!this.data.matSelect.ngControl?.errors;
  }

  constructor(@Inject(APP_SELECT_DATA) public data: {matSelect: MatSelect; label: string}) {}

  ngOnInit(): void {
    this.selectedValue = this.data.matSelect.triggerValue;
    // Need to use optionSelectionChanges because of https://github.com/angular/components/issues/15650
    this.selectionChange$ = this.data.matSelect.optionSelectionChanges.subscribe(() => (this.selectedValue = this.data.matSelect.triggerValue));
  }

  ngOnDestroy(): void {
    this.selectionChange$.unsubscribe();
  }

  @HostListener('click')
  private onClick(): void {
    this.data.matSelect.open();
  }
}
