import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {CgBusyDirective} from 'angular-busy2';
import {Subscription} from 'rxjs';
import {DeviceActivationEntity} from '../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {AppFormFieldComponent} from '../../shared/app-form-field/app-form-field.component';
import {AppInputErrorComponent} from '../../shared/app-form-field/app-input-error.component';
import {AppInputLabelComponent} from '../../shared/app-form-field/app-input-label.component';
import {AppInputDirective} from '../../shared/app-form-field/app-input.directive';

@Component({
  selector: 'app-sign-up-confirm-activation',
  templateUrl: './sign-up-confirm-activation.component.html',
  styleUrls: ['./sign-up-confirm-activation.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    AppFormFieldComponent,
    AppInputLabelComponent,
    AppInputDirective,
    AppInputErrorComponent,
    MatButtonModule,
    CgBusyDirective,
    TranslocoModule
  ]
})
export class SignUpConfirmActivationComponent implements OnInit, OnDestroy {
  clientActivationCode: string;
  loading$: Subscription;

  constructor(
    private router: Router,
    private deviceActivationEntity: DeviceActivationEntity,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.clientActivationCode = this.route.snapshot.paramMap.get('clientActivationCode');
    if (this.clientActivationCode) {
      this.clientActivate();
    }
  }

  ngOnDestroy(): void {
    this.loading$?.unsubscribe();
  }

  clientActivate() {
    this.loading$ = this.deviceActivationEntity.clientActivate(this.clientActivationCode).subscribe(device => {
      this.router.navigate(['activate', device.activationCode, 'complete']);
    });
  }
}
