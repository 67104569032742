<div class="center-container">
  <h2 class="mat-headline-6">{{ "ALARM_SETUP_DONE" | transloco }}</h2>
  <div class="svg">
    <svg width="100%" height="100%" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve">
      <clipPath id="clip">
        <circle cx="150" cy="150" r="150" />
      </clipPath>
      <g clip-path="url(#clip)">
        <circle class="border" cx="150" cy="150" r="150" />
        <path class="check" d="M78.872,138.91L129.929,189.967L219.473,100.422" />
      </g>
    </svg>
  </div>
  @if (alcedoURL) {
    <p>{{ "OPTIONS_ON_CLOUD" | transloco: {platformTitle: platformTitle} }}</p>
  }
  <div class="action-buttons">
    @if (alcedoURL) {
      <button (click)="goToAvelon()" mat-flat-button>
        <span>{{ "GO_TO_CLOUD" | transloco: {platformTitle: platformTitle} }}</span>
      </button>
    }
    <button color="primary" mat-flat-button routerLink="../info">
      <span>{{ "RETURN_TO_DEVICE" | transloco }}</span>
    </button>
  </div>
  <p class="push-notification-information">{{ "DOWNLOAD_MOBILE_APP_FOR_NOTIFICATIONS" | transloco }}</p>
  @if (!android) {
    <div class="ios-download">
      <a href="https://itunes.apple.com/us/app/alcedo-heads-up/id387335048" target="_blank" rel="noreferrer">
        <img src="assets/images/appstore-lrg.svg" />
      </a>
    </div>
  }
  @if (!iOS) {
    <div class="android-download">
      <a href="https://play.google.com/store/apps/details?id=ch.avelon.alcedo.android.alarms.release" target="_blank" rel="noreferrer">
        <img alt="Get it on Google Play" src="assets/images/google-play-badge.png" />
      </a>
    </div>
  }
</div>
