<form class="center-container">
  @if (step === 1) {
    <div ngModelGroup="accountTypeForm" #accountTypeForm="ngModelGroup">
      <h2 class="mat-headline-6">{{ "HOW_WILL_YOU_USE_THE_DEVICE" | transloco }}</h2>
      <mat-radio-group #accountType="ngModel" [(ngModel)]="newClient.accountType" class="account-type-selector" name="accountType">
        <mat-radio-button required [value]="AccountType.COMPANY">
          <mat-icon>business</mat-icon>
          <span>{{ "BUSINESS_ACCOUNT" | transloco }}</span>
        </mat-radio-button>
        <mat-radio-button required [value]="AccountType.INDIVIDUAL">
          <mat-icon>person</mat-icon>
          <span>{{ "PRIVATE_ACCOUNT" | transloco }}</span>
        </mat-radio-button>
      </mat-radio-group>
      <div class="flex">
        @if (newClient.accountType === AccountType.COMPANY) {
          <app-form-field>
            <app-input-label>{{ "COMPANY_NAME" | transloco }}</app-input-label>
            <input #company="ngModel" #companyInput [(ngModel)]="newClient.createClientRequest.companyName" appInput name="company" required />
            @if (company.hasError("required")) {
              <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
            }
          </app-form-field>
        } @else if (newClient.accountType === AccountType.INDIVIDUAL) {
          <app-form-field>
            <app-input-label>{{ "FIRST_NAME" | transloco }}</app-input-label>
            <input
              #firstName="ngModel"
              #firstNameInput
              [(ngModel)]="newClient.firstName"
              (ngModelChange)="updateModels()"
              appInput
              name="firstName"
              required />
            @if (firstName.hasError("required")) {
              <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
            }
          </app-form-field>
          <app-form-field>
            <app-input-label>{{ "LAST_NAME" | transloco }}</app-input-label>
            <input #lastName="ngModel" [(ngModel)]="newClient.lastName" (ngModelChange)="updateModels()" appInput name="lastName" required />
            @if (lastName.hasError("required")) {
              <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
            }
          </app-form-field>
        }
      </div>
      <div class="action-buttons">
        <button [disabled]="accountTypeForm.invalid" (click)="step = this.hasPaidLicenses ? 2 : 3" mat-flat-button color="primary">
          <span>{{ "NEXT" | transloco }}</span>
        </button>
      </div>
    </div>
  } @else if (step === 2) {
    <app-sign-up-business [newClient]="newClient" [userTitles]="userTitles" (stepChanged)="step = $event" @routeAnimation />
  } @else {
    <app-sign-up-private
      [newClient]="newClient"
      [userTitles]="userTitles"
      [UserLocalesLabels]="UserLocalesLabels"
      [hasPaidLicenses]="hasPaidLicenses"
      (stepChanged)="step = $event"
      @routeAnimation />
  }
</form>
