import {AfterViewInit, Component, ContentChild, ContentChildren, QueryList} from '@angular/core';
import {AppInputErrorComponent} from './app-input-error.component';
import {AppInputLabelComponent} from './app-input-label.component';
import {AppInputDirective} from './app-input.directive';

@Component({
  selector: 'app-form-field',
  templateUrl: './app-form-field.component.html',
  styleUrls: ['./app-form-field.component.scss'],
  standalone: true
})
export class AppFormFieldComponent implements AfterViewInit {
  @ContentChild(AppInputDirective) input: AppInputDirective;
  @ContentChild(AppInputLabelComponent) label: AppInputLabelComponent;
  @ContentChildren(AppInputErrorComponent) errors: QueryList<AppInputErrorComponent>;

  ngAfterViewInit(): void {
    if (!this.input) {
      console.error('Please import the AppInputDirective!');
    }
  }
}
