<div class="center-container">
  <h2 class="mat-headline-6">{{ "SET_UP_ALARMS" | transloco }}</h2>
  <div class="action-buttons">
    <button color="primary" mat-flat-button routerLink="../alerting-configure">
      <span>{{ "CONFIGURE_ALARMS" | transloco }}</span>
    </button>
    <button mat-flat-button routerLink="../alerting-copy">
      <span>{{ "COPY_ALARM_SETTINGS" | transloco }}</span>
    </button>
  </div>
</div>
