<div class="center-container">
  <h2 class="mat-headline-6">{{ "SIGN_IN" | transloco }}</h2>
  <div class="action-buttons">
    <button color="primary" routerLink="../confirmClient" mat-flat-button type="button">
      <span>{{ "SIGN_IN" | transloco }}</span>
    </button>
    <button mat-flat-button routerLink="../sign-up" type="button">
      <span>{{ "CREATE_NEW_ACCOUNT" | transloco }}</span>
    </button>
    <div class="layout-column layout-align-center-center">
      <a href="#" (click)="goBack($event)">{{ "BACK" | transloco }}</a>
    </div>
  </div>
</div>
