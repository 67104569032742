import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Title} from '@angular/platform-browser';
import {ChildrenOutletContexts, RouterOutlet} from '@angular/router';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {TranslocoService} from '@jsverse/transloco';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {CgBusyDefaults} from 'angular-busy2';
import {ToastrService} from 'ngx-toastr';
import {combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';
import {UserSessionService} from '../../../alcedo/src/app/shared/entities/auth/user-session.service';
import {Alcedo7User} from '../../../alcedo/src/app/shared/entities/user/avelon-user.service';
import {IotUserLanguages} from '../../../alcedo/src/app/shared/properties/user/user-languages.enum';
import {checkDisplayToast} from '../../../alcedo/src/app/shared/services/release-version';
import {Translate} from '../../../alcedo/src/app/shared/services/translate.service';
import {environment} from '../environments/environment';
import {slideInAnimation} from './core/animation';
import {AuthService} from './core/auth.service';
import {BrandService} from './core/brand.service';
import {DeviceService} from './core/services/device.service';
import {IoTService} from './core/services/iot.service';
import {SystemService} from './core/system.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
  standalone: true,
  imports: [RouterOutlet, MatProgressSpinnerModule]
})
export class AppComponent implements OnInit {
  languages: string[] = Object.values(IotUserLanguages);

  @ViewChild('customBusyTemplate', {static: true})
  private set customBusyTemplateTpl(customBusyTemplateTpl: TemplateRef<any>) {
    this.busyDefaults.templateRef = customBusyTemplateTpl;
  }

  constructor(
    public translate: TranslocoService,
    private brandService: BrandService,
    private systemService: SystemService,
    private toastrService: ToastrService,
    private updates: SwUpdate,
    private busyDefaults: CgBusyDefaults,
    private auth: AuthService,
    private iotService: IoTService,
    private title: Title,
    private contexts: ChildrenOutletContexts,
    private oidcSecurityService: OidcSecurityService,
    private deviceService: DeviceService
  ) {
    // remove old auth tokens from localstorage
    localStorage.removeItem('clientId');
    localStorage.removeItem('token');

    Translate.setInstance(translate);
    translate.setDefaultLang('en');

    const lang = AppComponent.getLang().split('-')[0];
    if (this.languages.indexOf(lang) > -1) {
      translate.setActiveLang(lang);
    }

    Alcedo7User.isIoT = true;

    this.busyDefaults.backdrop = false;
    this.updateTitle();
    updates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(event => checkDisplayToast(event, environment.version, () => this.displayUpdateToast()));
  }

  static getLang() {
    if (navigator.languages !== undefined && navigator.languages[0]) {
      return navigator.languages[0];
    } else {
      return navigator.language;
    }
  }

  ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe({
      next: ({isAuthenticated}) => {
        if (isAuthenticated && !Alcedo7User.currentUser) {
          this.auth.loadCurrentUser().subscribe(() => {
            this.iotService.reloadDevices.next();
            this.auth.resolvedAuth = true;
          });
        } else if (!isAuthenticated && UserSessionService.getSessionDummy()) {
          this.oidcSecurityService.authorize();
        } else {
          // fetch token for live values subscriptions
          this.deviceService.getPublicToken().subscribe();
          this.auth.resolvedAuth = true;
        }
      },
      error: () => {
        if (location.search.startsWith('?state=')) {
          // ERROR could not find matching config for state
          // this happens when resetting password and user opens email link in a new browser tab
          // the oidc library should authorize() again instead of throwing an error
          this.oidcSecurityService.authorize();
        }
      }
    });

    // Fetch the URLs now so later calls to this function will get the cached values directly.
    this.systemService.getURLs();
  }

  public getRouterOutletState(): string {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  private updateTitle(): void {
    this.brandService.getTitle().subscribe((title: string) => this.title.setTitle(title));
  }

  private displayUpdateToast(): void {
    const titleTranslate = this.translate.selectTranslate('UPDATE_READY');
    const bodyTranslate = this.translate.selectTranslate('UPDATE_APPLY');
    combineLatest([titleTranslate, bodyTranslate]).subscribe(response => {
      this.toastrService
        .info(response[1], response[0], {disableTimeOut: true})
        .onTap.subscribe(() => this.updates.activateUpdate().then(() => document.location.reload()));
    });
  }
}
