<div class="center-container">
  <h2 class="mat-headline-6">{{ "DO_YOU_WANT_TO_SET_UP_AN_ALERTING" | transloco }}</h2>
  <div class="svg">
    <svg width="100%" height="100%" viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve">
      <clipPath id="clip">
        <circle cx="150" cy="150" r="150" />
      </clipPath>
      <g clip-path="url(#clip)">
        <circle class="border" cx="150" cy="150" r="150" />
        <path class="wave1" d="M209.858,121.766c4.193,7.998 6.566,17.097 6.566,26.746c0,20.067 -10.261,37.757 -25.818,48.103" />
        <path class="wave2" d="M224.29,105.309c8.187,12.398 12.954,27.248 12.954,43.203c0,30.138 -17.009,56.334 -41.943,69.504" />
        <path class="wave3" d="M239.251,93.64c10.676,15.634 16.921,34.53 16.921,54.872c0,37.924 -21.704,70.819 -53.359,86.93" />
        <circle class="headstock" cx="80" cy="80" r="10" />
        <path
          class="bell"
          d="M202.66,159.059l-115.211,66.517c1.221,-33.173 3.215,-46.131 -14.678,-77.123c-17.893,-30.992 -0.987,-56.691 10.424,-63.28c11.411,-6.587 42.12,-8.379 60.013,22.613c17.893,30.992 30.113,35.744 59.452,51.273Z"
        />
        <circle class="clapper" cx="118" cy="213.48" r="15.811" />
      </g>
    </svg>
  </div>
  <p>{{ "SET_UP_ALERTING_ANYTIME" | transloco }}</p>
  <div class="action-buttons ok-cancel">
    <button [routerLink]="['/device', activationCode, 'alerting-choice']" class="ok" color="primary" mat-flat-button>
      <span>{{ "YES" | transloco }}</span>
    </button>
    <button [routerLink]="['/device', activationCode, 'info']" class="cancel" mat-flat-button>
      <span>{{ "SKIP" | transloco }}</span>
    </button>
  </div>
</div>
