<div class="center-container">
  <form (ngSubmit)="createOrUpdateRealEstate()" class="center-container">
    <h2 class="mat-headline-6">{{ "ADD_PRODUCT_TO_REAL_ESTATE" | transloco }}</h2>
    @if (canUseGPS === false) {
      <alc-message class="info" icon="info">
        <span>{{ "CANNOT_ACCESS_GPS" | transloco }}</span>
        <span>{{ "SPECIFY_LOCATION_MANUALLY" | transloco }}</span>
      </alc-message>
    }
    <mat-form-field appSelect [appSelectLabel]="'REAL_ESTATE' | transloco">
      <mat-select
        #selectedRealEstateModel="ngModel"
        [(ngModel)]="selectedRealEstate"
        (ngModelChange)="onRealEstateChange()"
        name="selectedRealEstate"
        required>
        @for (realEstate of realEstatesLabels; track realEstate) {
          <mat-option [value]="realEstate">{{ realEstate.label }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (selectedRealEstateModel.hasError("required")) {
      <alc-message class="error" icon="error">
        {{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}
      </alc-message>
    }
    <div class="action-buttons">
      @if (showFilteredRealEstates) {
        <button (click)="clearGPS()" class="gps-location" mat-flat-button type="button">
          <mat-icon>gps_off</mat-icon>
          <span>{{ "CLEAR_REAL_ESTATE_FILTER" | transloco }}</span>
        </button>
      } @else {
        <button (click)="handleGPSClick()" class="gps-location" mat-flat-button type="button">
          <mat-icon>gps_fixed</mat-icon>
          <span>{{ "NEARBY_REAL_ESTATES" | transloco }}</span>
        </button>
      }
    </div>
    <div class="action-buttons">
      <button [disabled]="selectedRealEstateModel.invalid" mat-flat-button type="submit" class="mat-primary">
        <span>{{ "OK" | transloco }}</span>
      </button>
    </div>
    <div class="action-buttons">
      <a routerLink="../real-estate-add">
        <span>{{ "CREATE_NEW_REAL_ESTATE" | transloco }}</span>
      </a>
      <a routerLink="../alerting-ask">
        <span>{{ "SKIP" | transloco }}</span>
      </a>
    </div>
  </form>
</div>
