<div class="center-container">
  <h2 class="mat-headline-6">{{ "NO_ELIGIBLE_ALARM_CHAINS" | transloco }}</h2>
  <p>{{ "NO_ELIGIBLE_ALARM_CHAINS_MESSAGE_1" | transloco }}</p>
  <p>{{ "NO_ELIGIBLE_ALARM_CHAINS_MESSAGE_2" | transloco }}</p>
  <p>{{ "NO_ELIGIBLE_ALARM_CHAINS_MESSAGE_3" | transloco: {platformTitle: platformTitle} }}</p>
  <div class="action-buttons">
    @if (alcedoURL) {
      <button (click)="goToAvelon()" mat-flat-button>
        <span>{{ "GO_TO_CLOUD" | transloco: {platformTitle: platformTitle} }}</span>
      </button>
    }
    <button color="primary" mat-flat-button routerLink="../info">
      <span>{{ "RETURN_TO_DEVICE" | transloco }}</span>
    </button>
  </div>
</div>
