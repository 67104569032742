<div [formGroup]="formGroup" class="center-container">
  <h2 class="mat-headline-6">{{ "ALARM_SETUP" | transloco }}</h2>
  @if (eligibleAlarmChains && eligibleAlarmChains.length > 2) {
    <mat-form-field appSelect [appSelectLabel]="'ALARM_CHAIN' | transloco">
      <mat-select formControlName="selectedAlarmChain">
        @for (alarmChain of eligibleAlarmChains; track alarmChain) {
          <mat-option [value]="alarmChain">{{ alarmChain.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @if (formGroup.get("alarmSetups")) {
    <div formArrayName="alarmSetups">
      @for (alarmSetup of enabledAlarmSetups; track alarmSetup; let i = $index) {
        <div [formGroupName]="i.toString()" class="alarm-setup">
          <div class="alarm-setup-heading">
            <label>{{ alarmSetup.label }}</label>
            <button (click)="removeAlarmSetup(alarmSetup)" mat-icon-button>
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          @if (alarmSetup.type === IotAlarmSetupType.DEVICE_WATCHDOG) {
            <label>{{ "WAITING_PERIOD_BEFORE_ALARM" | transloco }}</label>
            <app-form-field>
              <input [step]="alarmSetup.stepSize" formControlName="value" appInput min="1" required type="number" />
              <span appInputSuffix>{{ alarmSetup.unit }}</span>
              @if (getField(i, "value").hasError("required")) {
                <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
              }
              @if (getField(i, "value").hasError("min")) {
                <app-input-error>{{ "VALIDATION_ERROR_MIN_VALUE" | transloco: {min: 1} }}</app-input-error>
              }
            </app-form-field>
          } @else if (alarmSetup.type === IotAlarmSetupType.BATTERY_ALARM) {
            <label>{{ "BATTERY_THRESHOLD" | transloco }}</label>
            <app-form-field>
              <input [step]="alarmSetup.stepSize" formControlName="value" appInput max="90" min="1" name="value" required type="number" />
              <span appInputSuffix>{{ alarmSetup.unit }}</span>
              @if (getField(i, "value").hasError("required")) {
                <app-input-error>{{ "VALIDATION_ERROR_FIELD_REQUIRED" | transloco }}</app-input-error>
              }
              @if (getField(i, "value").hasError("min")) {
                <app-input-error>{{ "VALIDATION_ERROR_MIN_VALUE" | transloco: {min: 1} }}</app-input-error>
              }
              @if (getField(i, "value").hasError("max")) {
                <app-input-error>{{ "VALIDATION_ERROR_MAX_VALUE" | transloco: {max: 90} }}</app-input-error>
              }
            </app-form-field>
          } @else if (alarmSetup.type === IotAlarmSetupType.DATA_POINT) {
            <label>{{ "HIGH_LIMIT" | transloco }}</label>
            <app-form-field>
              <input formControlName="highLimit" appInput name="highLimit" [step]="alarmSetup.stepSize" type="number" />
              <span appInputSuffix>{{ alarmSetup.unit }}</span>
            </app-form-field>
            @if (alarmSetup.preHighLimit !== undefined) {
              <label>{{ "PRE_HIGH_LIMIT" | transloco }}</label>
              <app-form-field>
                <input formControlName="preHighLimit" appInput name="preHighLimit" [step]="alarmSetup.stepSize" type="number" />
                <span appInputSuffix>{{ alarmSetup.unit }}</span>
              </app-form-field>
            }
            @if (alarmSetup.preLowLimit !== undefined) {
              <label>{{ "PRE_LOW_LIMIT" | transloco }}</label>
              <app-form-field>
                <input formControlName="preLowLimit" appInput name="preLowLimit" [step]="alarmSetup.stepSize" type="number" />
                <span appInputSuffix>{{ alarmSetup.unit }}</span>
              </app-form-field>
            }
            <label>{{ "LOW_LIMIT" | transloco }}</label>
            <app-form-field>
              <input [step]="alarmSetup.stepSize" formControlName="lowLimit" appInput name="lowLimit" type="number" />
              <span appInputSuffix>{{ alarmSetup.unit }}</span>
            </app-form-field>
            @if (controls[i].hasError("notInRange")) {
              <alc-message class="error" icon="error">
                {{ "ALARM_LIMITS_ERROR" | transloco }}
              </alc-message>
            }
          }
        </div>
      }
    </div>
  }

  <div class="action-buttons">
    <mat-menu #dashboardMenu="matMenu" [overlapTrigger]="true">
      @for (alarmSetup of disabledAlarmSetups; track alarmSetup) {
        <div (click)="addAlarmSetup(alarmSetup)" mat-menu-item>
          <span>{{ alarmSetup.label }}</span>
        </div>
      }
    </mat-menu>
    <button
      [disabled]="enabledAlarmSetups?.length === deviceAlarmSetup?.limit || !disabledAlarmSetups?.length"
      [matMenuTriggerFor]="dashboardMenu"
      type="button"
      mat-flat-button>
      <mat-icon>add</mat-icon>
      <span>{{ "ADD_ANOTHER_CHECK" | transloco }}</span>
    </button>
    <button (click)="saveDeviceAlarmSetups()" [disabled]="formGroup.invalid" color="primary" type="submit" mat-flat-button>
      <span>{{ "OK" | transloco }}</span>
    </button>
  </div>
  <ng-container [cgBusy]="loading"></ng-container>
</div>
