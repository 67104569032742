import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlarmChainSummaryTO} from 'api/entities';
import {apiVersion} from '../../../../../alcedo/src/app/api-version.constant';
import {Alcedo7User} from '../../../../../alcedo/src/app/shared/entities/user/avelon-user.service';

export enum IotAlarmSetupType {
  BATTERY_ALARM = 'battery',
  DEVICE_WATCHDOG = 'watchdog',
  DATA_POINT = 'data_point'
}

export interface IotAlarmSetup {
  id: number;
  enabled: boolean;
  label: string;
  type: IotAlarmSetupType;
  alarmChainId: number;
  stepSize: number;
  lowLimit?: number;
  highLimit?: number;
  unit?: string;
  preLowLimit?: number;
  preHighLimit?: number;
  value?: number;
}

export interface IotAlarmSetups {
  limit: number;
  alarmSetups: IotAlarmSetup[];
}

@Injectable({
  providedIn: 'root'
})
export class AlertingService {
  constructor(private http: HttpClient) {}

  getCopyEligibleDevices(activationCode: string) {
    return this.http.get<{id: number; name: string}[]>('api/v1/360/devices/' + activationCode + '/copyEligibleDevices');
  }

  copyAlarmSetups(activationCode: string, deviceId: number) {
    return this.http.put('api/v1/360/devices/' + activationCode + '/copyAlarmSetups/' + deviceId, {});
  }

  getDeviceAlarmSetups(activationCode: string) {
    return this.http.get<IotAlarmSetups>('api/v1/360/devices/' + activationCode + '/alarmSetups');
  }

  updateDeviceAlarmSetups(activationCode: string, alarmSetups: IotAlarmSetup[]) {
    return this.http.put('api/v1/360/devices/' + activationCode + '/alarmSetups', alarmSetups);
  }

  findAllByClientAndDevice(deviceId: number, clientId?: number) {
    return this.http.post<AlarmChainSummaryTO[]>(
      apiVersion + 'clients/' + (clientId || Alcedo7User.selectedClient.id) + '/devices/' + deviceId + '/alarmChains',
      {}
    );
  }
}
