<div
  class="app-input-label"
  [class.input-has-value]="input.elementRef.nativeElement.value"
  [class.input-is-required]="label && input.elementRef.nativeElement.required"
  [class.error]="errors.length && input.formControl.dirty">
  <ng-content select="app-input-label"></ng-content>
</div>
<div class="input-wrapper">
  <ng-content select="[appInput]" class="app-input"></ng-content>
  <ng-content select="[appInputSuffix]" class="app-input-suffix"></ng-content>
  <div class="app-form-field-border" [class.error]="errors.length && input.formControl.dirty"></div>
</div>
<div class="app-input-error" [class.pristine]="input.formControl.pristine">
  <ng-content select="app-input-error"></ng-content>
</div>
