import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {CgBusyDirective} from 'angular-busy2';
import {Subscription} from 'rxjs';
import {orderByLocale} from '../../../../../../alcedo/src/app/shared/services/order-by-locale.service';
import {AlertingService} from '../../../core/services/alerting.service';
import {AppSelectDirective} from '../../shared/app-select/app-select.directive';

@Component({
  selector: 'app-alerting-copy',
  templateUrl: './alerting-copy.component.html',
  styleUrls: ['./alerting-copy.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    AppSelectDirective,
    MatSelectModule,
    FormsModule,
    MatOptionModule,
    MatButtonModule,
    RouterLink,
    CgBusyDirective,
    TranslocoModule
  ]
})
export class AlertingCopyComponent implements OnInit {
  devices: {id: number; name: string}[] = [];
  selectedDevice: {id: number; name: string};
  loading: Subscription;

  constructor(
    private alertingService: AlertingService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const activationCode = this.route.snapshot.params.activationCode;
    this.alertingService.getCopyEligibleDevices(activationCode).subscribe(devices => {
      this.devices = orderByLocale(devices, 'name');
      this.selectedDevice = this.devices[0];
    });
  }

  copyAlerting() {
    const activationCode = this.route.snapshot.params.activationCode;
    this.loading = this.alertingService.copyAlarmSetups(activationCode, this.selectedDevice.id).subscribe(() => {
      this.router.navigate(['../alerting-complete'], {relativeTo: this.route});
    });
  }
}
