import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {TranslocoService} from '@jsverse/transloco';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {apiVersion} from '../../../../alcedo/src/app/api-version.constant';
import {UserSessionService} from '../../../../alcedo/src/app/shared/entities/auth/user-session.service';
import {ErrorInterceptorHandles} from './error-interceptor-handles';

export function errorInterceptor(request: HttpRequest<any>, handle: HttpHandlerFn): Observable<HttpEvent<any>> {
  if (!request.url.startsWith(apiVersion)) {
    return handle(request);
  }

  const toastrService: ToastrService = inject(ToastrService);
  const translocoService: TranslocoService = inject(TranslocoService);
  const errorMessage = translocoService.selectTranslate('GENERAL.TITLE.ERROR');
  const oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);
  return handle(request).pipe(tap({error: err => handleErrors(err, toastrService, oidcSecurityService, errorMessage)}));
}

function handleErrors(err: any, toastrService: ToastrService, oidcSecurityService: OidcSecurityService, errorMessage: Observable<string>): void {
  const errorHandles = new ErrorInterceptorHandles(toastrService, errorMessage);
  if (err instanceof HttpErrorResponse) {
    checkErrorCurrentUser(err, oidcSecurityService);
    if (err.error && err.error instanceof Blob) {
      errorHandles.handleResponseTypeBlob(err);
    } else {
      errorHandles.handleErrorMessage(err);
    }
  }
}

function checkErrorCurrentUser(err: HttpErrorResponse, oidcSecurityService: OidcSecurityService): void {
  if ((err.status === 401 || err.status === 404) && err.url.indexOf('users/current') > -1) {
    UserSessionService.clearUserFromMemory();
    oidcSecurityService.logoff().subscribe();
  }
}
