import {marker} from '@jsverse/transloco-keys-manager/marker';
import {UserTitle} from 'api/entities';
import {Translate} from '../../../../alcedo/src/app/shared/services/translate.service';

export const UserTitleLabels: Record<UserTitle, string> = {
  get [UserTitle.NONE]() {
    return Translate.instant(marker('USER_TITLE.NONE'));
  },
  get [UserTitle.MR]() {
    return Translate.instant(marker('USER_TITLE.MR'));
  },
  get [UserTitle.MRS]() {
    return Translate.instant(marker('USER_TITLE.MRS'));
  }
};
