import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Params, Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {forkJoin, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AlertingService} from '../../../core/services/alerting.service';
import {IoTService} from '../../../core/services/iot.service';

@Component({
  selector: 'app-alerting-choice',
  templateUrl: './alerting-choice.component.html',
  styleUrls: ['./alerting-choice.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class AlertingChoiceComponent implements OnInit {
  private route$: Subscription;

  constructor(
    private alertingService: AlertingService,
    private route: ActivatedRoute,
    private router: Router,
    private iotService: IoTService
  ) {}

  ngOnInit() {
    this.route$ = this.route.params
      .pipe(
        switchMap((params: Params) => {
          return this.iotService.loadDevices().pipe(map(() => params));
        }),
        switchMap((params: Params) => {
          const device = this.iotService.getDeviceByActivationCode(params.activationCode);
          const devices = this.alertingService.getCopyEligibleDevices(params.activationCode);
          const eligibleAlarmChains = this.alertingService.findAllByClientAndDevice(device.id, device.clientId);
          return forkJoin([devices, eligibleAlarmChains]);
        })
      )
      .subscribe(([devices, alarmChains]) => {
        if (!devices.length) {
          this.router.navigate(['../alerting-configure'], {relativeTo: this.route, replaceUrl: true});
        }
        const eligibleAlarmChains = alarmChains.filter(alarmChain => alarmChain.eligible);
        if (!eligibleAlarmChains.length) {
          this.router.navigate(['../alerting-failure'], {relativeTo: this.route, replaceUrl: true});
        }
      });
  }
}
