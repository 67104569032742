import {Component, Input} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {TranslocoPipe} from '@jsverse/transloco';

@Component({
  selector: 'app-show-password',
  standalone: true,
  imports: [MatIcon, MatIconButton, TranslocoPipe, MatTooltip],
  templateUrl: './show-password.component.html'
})
export class ShowPasswordComponent {
  @Input({required: true}) passwordInput: HTMLInputElement;

  get isPasswordVisible(): boolean {
    return this.passwordInput.type === 'text';
  }

  togglePasswordVisibility(): void {
    this.passwordInput.type = this.isPasswordVisible ? 'password' : 'text';
  }
}
