@if (data.label) {
  <div [class.error]="showError" [class.input-is-required]="this.data.matSelect.required" class="app-input-label">
    {{ data.label }}
  </div>
}

<div class="select-wrapper">
  <div class="ellipsis">{{ selectedValue }}</div>
  <mat-icon [class.error]="showError">arrow_drop_down</mat-icon>
  <div [class.error]="showError" class="app-form-field-border"></div>
</div>

<div #errorContainer [class.hide-error]="!showError" class="app-input-error"></div>
