import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {CgBusyDirective} from 'angular-busy2';
import {ReplaceableDeviceTO} from 'api/entities';
import {Subscription} from 'rxjs';
import {DeviceActivationEntity} from '../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {orderByLocale} from '../../../../../../alcedo/src/app/shared/services/order-by-locale.service';
import {AppSelectDirective} from '../../shared/app-select/app-select.directive';

@Component({
  selector: 'app-replace-device',
  templateUrl: './replace-device.component.html',
  styleUrls: ['./replace-device.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    AppSelectDirective,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    RouterLink,
    CgBusyDirective,
    TranslocoModule
  ]
})
export class ReplaceDeviceComponent implements OnInit {
  loading: Subscription;
  replacedLocationId: number;
  devices: ReplaceableDeviceTO[];

  constructor(
    private deviceActivationEntity: DeviceActivationEntity,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const activationCode = this.route.snapshot.paramMap.get('activationCode');
    const clientId = +this.route.snapshot.paramMap.get('clientId');
    if (activationCode) {
      this.loading = this.deviceActivationEntity
        .getDeviceList(clientId, activationCode)
        .subscribe(response => (this.devices = orderByLocale(response, 'name')));
    }
  }
}
