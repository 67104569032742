import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of, throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {AuthEntity} from '../../../../../alcedo/src/app/shared/entities/auth/auth.entity';
import {ContactSupportComponent} from './contact-support/contact-support.component';
import {LicenseComponent} from './license.component';

@Injectable()
export class LicenseService {
  constructor(
    private authEntity: AuthEntity,
    private dialog: MatDialog
  ) {}

  showLicense(language: string, enforceLicenseAccept: boolean): Observable<any> {
    let getLicense: Observable<any>;
    if (language) {
      getLicense = this.authEntity.getLicenseForLanguage(language);
    } else {
      getLicense = this.authEntity.getLicense();
    }
    return getLicense.pipe(
      switchMap((response: string) => {
        return this.openDialog(response, enforceLicenseAccept);
      }),
      switchMap((response: boolean) => {
        if (response) {
          return of(response);
        } else if (enforceLicenseAccept) {
          return this.openContactSupportDialog();
        } else {
          return of(false);
        }
      })
    );
  }

  openDialog(licenseText: string, enforceLicenseAccept: boolean): Observable<any> {
    const dialogRef = this.dialog.open(LicenseComponent, {
      panelClass: 'license-dialog',
      data: {
        licenseText,
        enforceLicenseAccept
      }
    });

    return dialogRef.afterClosed();
  }

  openContactSupportDialog() {
    const dialogRef = this.dialog.open(ContactSupportComponent, {
      panelClass: 'license-dialog'
    });

    return dialogRef.afterClosed().pipe(
      switchMap((response: boolean) => {
        if (response) {
          return of(false);
        } else {
          return throwError(() => new Error('License not accepted!'));
        }
      })
    );
  }
}
