import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {MessageComponent} from '../../../../../../alcedo/src/app/shared/components/message/message.component';
import {SignUpService} from '../sign-up-private/sign-up.service';

@Component({
  selector: 'app-sign-up-confirm',
  templateUrl: './sign-up-confirm.component.html',
  styleUrls: ['./sign-up-confirm.component.scss'],
  standalone: true,
  imports: [MessageComponent, RouterLink, TranslocoModule]
})
export class SignUpConfirmComponent implements OnInit {
  activationCode: string;
  resendEmail: boolean;

  constructor(
    private signUpService: SignUpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activationCode = this.route.snapshot.paramMap.get('activationCode');
    this.resendEmail = this.signUpService.resendEmail;
  }
}
