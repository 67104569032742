<h2 mat-dialog-title>{{ "TERMS_OF_USE_TITLE" | transloco }}</h2>
<mat-dialog-content (scroll)="validateReadLicense($event)" [innerHTML]="data.licenseText" class="mat-typography"></mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-dialog-close mat-flat-button>
    @if (data.enforceLicenseAccept) {
      <span>{{ "DECLINE" | transloco }}</span>
    } @else {
      <span>{{ "CLOSE" | transloco }}</span>
    }
  </button>
  @if (data.enforceLicenseAccept) {
    <button [disabled]="disableAcceptButton" [mat-dialog-close]="true" cdkFocusInitial class="mat-primary" mat-flat-button>
      <span>{{ "ACCEPT" | transloco }}</span>
    </button>
  }
</mat-dialog-actions>
