import {KeyValuePipe} from '@angular/common';
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ControlContainer, FormsModule, NgForm, NgModel} from '@angular/forms';
import {MatOption} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatFormField} from '@angular/material/form-field';
import {MatSelect} from '@angular/material/select';
import {Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {CountryEnum, Currency, DeviceActivationNewClientRequestTO} from 'api/entities';
import {phrase} from 'passhelp';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {AccountType, getGPSCoordinatesSuccess} from '../../../../../../alcedo/src/app/access-control/sign-up/sign-up.static';
import {EMAIL_REGEX, PASSWORD_REGEX, PHONE_REGEX} from '../../../../../../alcedo/src/app/shared/constants/regular-expressions.constant';
import {unsubscribe} from '../../../../../../alcedo/src/app/shared/decorators/unsubscribe.decorator';
import {DeviceActivationEntity} from '../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {NewClient} from '../../../../../../alcedo/src/app/shared/entities/devices/device.interface';
import {UserLocales} from '../../../../../../alcedo/src/app/shared/properties/user/user-locales.enum';
import {LicenseService} from '../../../core/license/license.service';
import {AppFormFieldComponent} from '../../shared/app-form-field/app-form-field.component';
import {AppInputErrorComponent} from '../../shared/app-form-field/app-input-error.component';
import {AppInputLabelComponent} from '../../shared/app-form-field/app-input-label.component';
import {AppInputSuffixDirective} from '../../shared/app-form-field/app-input-suffix.directive';
import {AppInputDirective} from '../../shared/app-form-field/app-input.directive';
import {AppSelectDirective} from '../../shared/app-select/app-select.directive';
import {SignUpSteps} from '../sign-up-ask/sign-up-ask.component';
import {ShowPasswordComponent} from './show-password/show-password.component';
import {SignUpService} from './sign-up.service';

const RANDOM_PASSWORD_WORDS_COUNT = 3;

@Component({
  selector: 'app-sign-up-private',
  templateUrl: './sign-up-private.component.html',
  styleUrls: ['./sign-up-private.component.scss'],
  standalone: true,
  providers: [LicenseService],
  imports: [
    AppFormFieldComponent,
    AppInputLabelComponent,
    AppInputDirective,
    AppInputErrorComponent,
    MatButtonModule,
    TranslocoModule,
    RouterLink,
    AppSelectDirective,
    MatFormField,
    MatOption,
    MatSelect,
    KeyValuePipe,
    FormsModule,
    ShowPasswordComponent,
    AppInputSuffixDirective
  ],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SignUpPrivateComponent implements AfterViewInit, OnDestroy {
  @ViewChild('newPassword') newPassword: NgModel;
  @ViewChild('confirmPassword') confirmPassword: NgModel;
  @Input() newClient: NewClient;
  @Input() userTitles: Record<string, string>;
  @Input() UserLocalesLabels: Record<UserLocales, string>;
  @Input() hasPaidLicenses: boolean;
  @Output() stepChanged = new EventEmitter<SignUpSteps>();
  readonly PASSWORD_REGEX = PASSWORD_REGEX;
  readonly PHONE_REGEX = PHONE_REGEX;
  readonly EMAIL_REGEX = EMAIL_REGEX;
  readonly AccountType = AccountType;
  hadInvalidPassword: boolean;
  private loading$: Subscription;
  private showLicense$: Subscription;
  private formChanges$: Subscription;

  constructor(
    private router: Router,
    private deviceActivationEntity: DeviceActivationEntity,
    private licenseService: LicenseService,
    private signUpService: SignUpService
  ) {}

  ngAfterViewInit(): void {
    this.formChanges$ = this.newPassword.valueChanges.subscribe(() => {
      this.hadInvalidPassword = this.hadInvalidPassword || this.newPassword.hasError('pattern');
    });
  }

  ngOnDestroy(): void {
    unsubscribe(this);
  }

  validatePasswordConfirmation(): void {
    const confirmErrors = this.confirmPassword.errors;
    if (!confirmErrors || confirmErrors.mismatch) {
      const passwordConfirmed = this.newClient.createClientRequest.user.password === this.confirmPassword.value;
      this.confirmPassword.control.setErrors(passwordConfirmed ? null : {mismatch: true});
    }
  }

  displayUserLicense(): void {
    this.showLicense$ = this.licenseService.showLicense(navigator.language.split('-')[0], true).subscribe({
      next: (response: boolean) => {
        if (response) {
          this.newClient.createClientRequest.user.licenseAccepted = true;
          this.signUp();
        }
      },
      error: () => this.router.navigate(['./'])
    });
  }

  signUp(): void {
    this.newClient.createClientRequest.currency =
      this.newClient.createClientRequest.billingDetails?.country === CountryEnum.CH ? Currency.CHF : Currency.EUR;

    const createdClient: DeviceActivationNewClientRequestTO = {
      activationCode: this.newClient.activationCode,
      createClientRequest: this.newClient.createClientRequest,
      latitude: null,
      longitude: null
    };

    this.loading$ = this.deviceActivationEntity
      .getGPSFromAddress(this.getAddress())
      .pipe(
        switchMap(response => {
          Object.assign(createdClient, getGPSCoordinatesSuccess(response));

          return this.deviceActivationEntity.clientCreate(createdClient);
        })
      )
      .subscribe((response: {resendEmail: boolean}) => {
        this.signUpService.resendEmail = response.resendEmail;
        this.signUpService.emailUsed = this.newClient.createClientRequest.user.email;
        this.router.navigate(['activate', this.newClient.activationCode, 'confirmation']);
      });
  }

  suggestRandomPassword(passwordInput: HTMLInputElement): void {
    this.newClient.createClientRequest.user.password = phrase(RANDOM_PASSWORD_WORDS_COUNT, true);
    passwordInput.type = 'text';
  }

  private getAddress(): string {
    return (
      this.newClient.createClientRequest.billingDetails?.street +
      ' ' +
      this.newClient.createClientRequest.billingDetails?.zipCode +
      ' ' +
      this.newClient.createClientRequest.billingDetails?.city +
      ' ' +
      this.newClient.createClientRequest.billingDetails?.country
    );
  }
}
