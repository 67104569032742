import {marker} from '@jsverse/transloco-keys-manager/marker';
import {UserLocales} from '../../../../alcedo/src/app/shared/properties/user/user-locales.enum';
import {Translate} from '../../../../alcedo/src/app/shared/services/translate.service';

export const UserLocalesLabels: Record<UserLocales, string> = {
  get [UserLocales.CS_CZ]() {
    return Translate.instant(marker('USER_LOCALE.CS_CZ'));
  },
  get [UserLocales.DE_DE]() {
    return Translate.instant(marker('USER_LOCALE.DE_DE'));
  },
  get [UserLocales.DE_CH]() {
    return Translate.instant(marker('USER_LOCALE.DE_CH'));
  },
  get [UserLocales.DE_AT]() {
    return Translate.instant(marker('USER_LOCALE.DE_AT'));
  },
  get [UserLocales.DE_LI]() {
    return Translate.instant(marker('USER_LOCALE.DE_LI'));
  },
  get [UserLocales.DE_LU]() {
    return Translate.instant(marker('USER_LOCALE.DE_LU'));
  },
  get [UserLocales.EN_GB]() {
    return Translate.instant(marker('USER_LOCALE.EN_GB'));
  },
  get [UserLocales.FR_FR]() {
    return Translate.instant(marker('USER_LOCALE.FR_FR'));
  },
  get [UserLocales.FR_CH]() {
    return Translate.instant(marker('USER_LOCALE.FR_CH'));
  },
  get [UserLocales.FR_LU]() {
    return Translate.instant(marker('USER_LOCALE.FR_LU'));
  },
  get [UserLocales.IT_IT]() {
    return Translate.instant(marker('USER_LOCALE.IT_IT'));
  },
  get [UserLocales.IT_CH]() {
    return Translate.instant(marker('USER_LOCALE.IT_CH'));
  }
};
