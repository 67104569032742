<div class="center-container">
  <h2 class="mat-headline-6">{{ "SELECT_DEVICE_COPY_SETTINGS" | transloco }}</h2>
  <mat-form-field appSelect>
    <mat-select [(ngModel)]="selectedDevice">
      @for (device of devices; track device) {
        <mat-option [value]="device">{{ device.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div class="action-buttons">
    <button (click)="copyAlerting()" [disabled]="!selectedDevice" color="primary" mat-flat-button>
      <span>{{ "OK" | transloco }}</span>
    </button>
    <div class="layout-column layout-align-center-center">
      <a routerLink="../alerting-configure">
        <span>{{ "SET_UP_A_NEW_ALARM_INSTEAD" | transloco }}</span>
      </a>
    </div>
  </div>
  <ng-container [cgBusy]="loading"></ng-container>
</div>
