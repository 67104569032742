import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {RealEstateInVicinityTO, RealEstateProductActivationTO} from 'api/entities';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {LatLng} from '../../../../../../../alcedo/src/app/shared/components/map-location/gps-location.interface';
import {MessageComponent} from '../../../../../../../alcedo/src/app/shared/components/message/message.component';
import {DeviceActivationEntity} from '../../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {DeviceService} from '../../../../core/services/device.service';
import {AppFormFieldComponent} from '../../../shared/app-form-field/app-form-field.component';
import {AppInputErrorComponent} from '../../../shared/app-form-field/app-input-error.component';
import {AppInputLabelComponent} from '../../../shared/app-form-field/app-input-label.component';
import {AppInputDirective} from '../../../shared/app-form-field/app-input.directive';

@Component({
  selector: 'app-device-real-estate-add',
  templateUrl: './device-real-estate-add.component.html',
  styleUrls: ['./device-real-estate-add.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MessageComponent,
    AppFormFieldComponent,
    AppInputLabelComponent,
    AppInputDirective,
    AppInputErrorComponent,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    TranslocoModule
  ]
})
export class DeviceRealEstateAddComponent implements OnInit {
  canUseGPS = !!navigator.geolocation;
  dirtyName: boolean;
  loading: Subscription;
  activationCode: string;
  clientId: number;
  hideSelectExistingRealEstate = false;
  deviceData: RealEstateProductActivationTO = {
    name: '',
    location: '',
    latitude: null,
    longitude: null,
    deviceActivationInfo: {
      deviceName: '',
      activationCode: '',
      publicAccess: true
    }
  };
  gps: string;

  constructor(
    private deviceActivationEntity: DeviceActivationEntity,
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activationCode = this.route.snapshot.paramMap.get('activationCode');
    this.dirtyName = false;

    this.deviceService
      .getDevice(this.activationCode)
      .pipe(
        switchMap(device => {
          this.clientId = device.clientId;
          this.deviceData.deviceActivationInfo.deviceName = device.name;
          this.deviceData.deviceActivationInfo.activationCode = this.activationCode;
          return this.deviceActivationEntity.getProximityRealEstates(device.clientId, this.activationCode);
        })
      )
      .subscribe((response: RealEstateInVicinityTO[]) => {
        if (!response.length) {
          this.hideSelectExistingRealEstate = true;
        }
      });
  }

  onGpsChange() {
    const latLngArr = this.gps.split(',');
    let latLng: LatLng;
    if (latLngArr[1]) {
      latLng = {lat: parseFloat(latLngArr[0]), lng: parseFloat(latLngArr[1])};
      latLng = Number.isFinite(latLng.lng) && Number.isFinite(latLng.lat) ? latLng : null;
    }
    if (latLng) {
      this.deviceData.latitude = latLng.lat;
      this.deviceData.longitude = latLng.lng;
    }
  }

  handleGPSClick() {
    navigator.geolocation.getCurrentPosition(
      position => this.onGetGeoLocationSuccess(position),
      () => (this.canUseGPS = false)
    );
  }

  changeAddress() {
    this.deviceData.name = this.deviceData.location;
  }

  changeRealEstateName() {
    this.dirtyName = true;
  }

  createOrUpdateRealEstate() {
    this.loading = this.deviceActivationEntity.createOrUpdateRealEstate(this.clientId, this.deviceData).subscribe(() => {
      this.router.navigate(['../alerting-ask'], {relativeTo: this.route});
    });
  }

  private onGetGeoLocationSuccess(position) {
    if (position) {
      const lat = +position.coords.latitude.toFixed(6);
      const lng = +position.coords.longitude.toFixed(6);
      this.gps = lat + ', ' + lng;
      this.deviceData.latitude = lat;
      this.deviceData.longitude = lng;
      this.loading = this.deviceActivationEntity.getAddressFromGPS(lat + ',' + lng).subscribe(response => {
        if (response.results && response.results.length) {
          this.deviceData.location = response.results[0].formatted_address;
          if (!this.dirtyName) {
            this.deviceData.name = this.deviceData.location;
          }
        }
      });
    }
  }
}
