import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {CgBusyDirective} from 'angular-busy2';
import {AlarmChainSummaryTO} from 'api/entities';
import {forkJoin, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {MessageComponent} from '../../../../../../alcedo/src/app/shared/components/message/message.component';
import {unsubscribe} from '../../../../../../alcedo/src/app/shared/decorators/unsubscribe.decorator';
import {JSONUtils} from '../../../../../../alcedo/src/app/shared/services/json-utils.service';
import {orderByLocale} from '../../../../../../alcedo/src/app/shared/services/order-by-locale.service';
import {AlertingService, IotAlarmSetup, IotAlarmSetups, IotAlarmSetupType} from '../../../core/services/alerting.service';
import {IoTService} from '../../../core/services/iot.service';
import {AppFormFieldComponent} from '../../shared/app-form-field/app-form-field.component';
import {AppInputErrorComponent} from '../../shared/app-form-field/app-input-error.component';
import {AppInputSuffixDirective} from '../../shared/app-form-field/app-input-suffix.directive';
import {AppInputDirective} from '../../shared/app-form-field/app-input.directive';
import {AppSelectDirective} from '../../shared/app-select/app-select.directive';

@Component({
  selector: 'app-alerting-configure',
  templateUrl: './alerting-configure.component.html',
  styleUrls: ['./alerting-configure.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    AppSelectDirective,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
    AppFormFieldComponent,
    AppInputDirective,
    AppInputSuffixDirective,
    AppInputErrorComponent,
    MessageComponent,
    MatMenuModule,
    CgBusyDirective,
    TranslocoModule
  ]
})
export class AlertingConfigureComponent implements OnInit, OnDestroy {
  IotAlarmSetupType = IotAlarmSetupType;
  selectedAlarmChain: AlarmChainSummaryTO;
  eligibleAlarmChains: AlarmChainSummaryTO[];
  deviceAlarmSetup: IotAlarmSetups;
  enabledAlarmSetups: IotAlarmSetup[];
  disabledAlarmSetups: IotAlarmSetup[];
  loading: Subscription;
  formGroup: FormGroup = new FormGroup({
    selectedAlarmChain: new FormControl()
  });
  private originalDeviceAlarmSetup: IotAlarmSetups;
  private route$: Subscription;

  constructor(
    private alertingService: AlertingService,
    private route: ActivatedRoute,
    private iotService: IoTService,
    private router: Router
  ) {}

  get controls() {
    return (this.formGroup.get('alarmSetups') as FormArray).controls;
  }

  static isValue(field) {
    return field && field.value !== null;
  }

  static validateDataPointAlarmSetup(formGroup: FormGroup) {
    const validateArray = [];
    const highLimit = formGroup.get('highLimit');
    const preHighLimit = formGroup.get('preHighLimit');
    const preLowLimit = formGroup.get('preLowLimit');
    const lowLimit = formGroup.get('lowLimit');
    if (AlertingConfigureComponent.isValue(highLimit)) {
      validateArray.push(highLimit.value);
    }
    if (AlertingConfigureComponent.isValue(preHighLimit)) {
      validateArray.push(preHighLimit.value);
    }
    if (AlertingConfigureComponent.isValue(preLowLimit)) {
      validateArray.push(preLowLimit.value);
    }
    if (AlertingConfigureComponent.isValue(lowLimit)) {
      validateArray.push(lowLimit.value);
    }
    let allLimitsValid = true;
    if (validateArray.length > 1) {
      let i = validateArray.length - 1;
      for (; i > 0; i--) {
        allLimitsValid = validateArray[i] <= validateArray[i - 1];
        if (!allLimitsValid) {
          break;
        }
      }
    } else {
      allLimitsValid = true;
    }
    if (allLimitsValid) {
      return null;
    } else {
      return {notInRange: !allLimitsValid};
    }
  }

  filterAlarmSetups() {
    this.enabledAlarmSetups = this.deviceAlarmSetup.alarmSetups.filter(setup => setup.enabled);
    this.disabledAlarmSetups = orderByLocale(
      this.deviceAlarmSetup.alarmSetups.filter(setup => !setup.enabled),
      'label'
    );
  }

  ngOnInit() {
    this.route$ = this.route.params
      .pipe(
        switchMap((params: Params) => {
          return this.iotService.loadDevices().pipe(map(() => params));
        }),
        switchMap((params: Params) => {
          const device = this.iotService.getDeviceByActivationCode(params.activationCode);
          const deviceAlarmSetup = this.alertingService.getDeviceAlarmSetups(params.activationCode);
          const eligibleAlarmChains = this.alertingService.findAllByClientAndDevice(device.id, device.clientId);
          return forkJoin([deviceAlarmSetup, eligibleAlarmChains]);
        })
      )
      .subscribe(([deviceAlarmSetup, eligibleAlarmChains]) => {
        this.originalDeviceAlarmSetup = deviceAlarmSetup;
        this.deviceAlarmSetup = JSONUtils.clone(deviceAlarmSetup);
        this.eligibleAlarmChains = orderByLocale(
          eligibleAlarmChains.filter(alarmChain => alarmChain.eligible),
          'name'
        );
        this.filterAlarmSetups();
        const formArray = this.enabledAlarmSetups.map(alarmSetup => this.createAlarmSetupFormGroup(alarmSetup));
        this.formGroup.addControl('alarmSetups', new FormArray(formArray));
        if (this.enabledAlarmSetups[0]) {
          this.selectedAlarmChain = this.eligibleAlarmChains.find(alarmChain => alarmChain.id === this.enabledAlarmSetups[0].alarmChainId);
          if (!this.selectedAlarmChain) {
            this.selectedAlarmChain = this.eligibleAlarmChains[0];
          }
        } else {
          this.selectedAlarmChain = this.eligibleAlarmChains[0];
        }
        if (!this.selectedAlarmChain) {
          this.router.navigate(['../alerting-failure'], {relativeTo: this.route, replaceUrl: true});
        }
        this.formGroup.controls.selectedAlarmChain.setValue(this.selectedAlarmChain);
      });
    this.formGroup.controls.selectedAlarmChain.valueChanges.subscribe(value => this.setAlarmChain(value));
  }

  ngOnDestroy() {
    unsubscribe(this);
  }

  addAlarmSetup(alarmSetup: IotAlarmSetup) {
    alarmSetup.enabled = true;
    alarmSetup.alarmChainId = this.selectedAlarmChain ? this.selectedAlarmChain.id : null;
    this.filterAlarmSetups();
    const i = this.enabledAlarmSetups.indexOf(alarmSetup);
    (this.formGroup.controls.alarmSetups as FormArray).insert(i, this.createAlarmSetupFormGroup(alarmSetup));
  }

  setAlarmChain(alarmChain: AlarmChainSummaryTO) {
    if (alarmChain) {
      this.enabledAlarmSetups.forEach(setup => (setup.alarmChainId = alarmChain.id));
    }
  }

  saveDeviceAlarmSetups() {
    const setups = this.formGroup.value.alarmSetups;
    setups.forEach(setup => {
      const alarm = this.enabledAlarmSetups.find(s => s.id === setup.id);
      Object.assign(alarm, setup);
    });
    this.loading = this.alertingService
      .updateDeviceAlarmSetups(this.route.snapshot.params.activationCode, this.deviceAlarmSetup.alarmSetups)
      .subscribe(() => {
        Object.assign(this.originalDeviceAlarmSetup, this.deviceAlarmSetup);
        this.router.navigate(['../alerting-complete'], {relativeTo: this.route});
      });
  }

  removeAlarmSetup(alarmSetup: IotAlarmSetup) {
    alarmSetup.enabled = false;
    alarmSetup.alarmChainId = null;
    const i = this.enabledAlarmSetups.indexOf(alarmSetup);
    (this.formGroup.controls.alarmSetups as FormArray).removeAt(i);
    this.filterAlarmSetups();
  }

  createAlarmSetupFormGroup(alarmSetup: IotAlarmSetup): FormGroup {
    const controls: {[key: string]: FormControl} = {};
    const validators = [];
    controls.id = new FormControl(alarmSetup.id);
    switch (alarmSetup.type) {
      case IotAlarmSetupType.DEVICE_WATCHDOG:
        controls.value = new FormControl(alarmSetup.value, [Validators.required, Validators.min(1)]);
        break;
      case IotAlarmSetupType.BATTERY_ALARM:
        controls.value = new FormControl(alarmSetup.value, [Validators.required, Validators.min(1), Validators.max(90)]);
        break;
      case IotAlarmSetupType.DATA_POINT:
        if (alarmSetup.preHighLimit !== undefined) {
          controls.preHighLimit = new FormControl(alarmSetup.preHighLimit);
          controls.preLowLimit = new FormControl(alarmSetup.preLowLimit);
        }
        controls.highLimit = new FormControl(alarmSetup.highLimit);
        controls.lowLimit = new FormControl(alarmSetup.lowLimit);
        validators.push(AlertingConfigureComponent.validateDataPointAlarmSetup);
        break;
    }

    return new FormGroup(controls, validators);
  }

  getField(i: number, field: string) {
    return this.formGroup.get('alarmSetups').get(i.toString()).get(field);
  }
}
