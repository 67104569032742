import {animate, animateChild, group, query, style, transition, trigger} from '@angular/animations';

export const slideInAnimation = trigger('routeAnimation', [
  transition(
    'DeviceHub => DeviceInfo, DeviceHub => DataPointInfo, DeviceHub => Quiz, DeviceHub => FeatureIntro, ' +
      'DeviceInfo => FeatureIntro, DeviceInfo => Registration, DeviceInfo => AlarmSetup, FeatureIntro => Registration, ' +
      'Registration => Registration, AlarmSetup => AlarmSetup',
    [
      style({position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%'
          })
        ],
        {optional: true}
      ),
      query(':enter', [style({transform: 'translateX(100%)'})], {optional: true}),
      query(':leave', animateChild(), {optional: true}),
      group([
        query(':leave', [animate('1000ms cubic-bezier(0.23, 1, 0.32, 1)', style({transform: 'translateX(-100%)'}))], {optional: true}),
        query(':enter', [animate('1000ms cubic-bezier(0.23, 1, 0.32, 1)', style({transform: 'translateX(0%)'}))], {optional: true})
      ]),
      query(':enter', animateChild(), {optional: true})
    ]
  ),
  transition('DeviceInfo => DeviceHub, DataPointInfo => DeviceHub, Quiz => DeviceHub, FeatureIntro => DeviceHub', [
    style({position: 'relative'}),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ],
      {optional: true}
    ),
    query(':enter', [style({transform: 'translateX(-100%)'})], {optional: true}),
    query(':leave', animateChild(), {optional: true}),
    group([
      query(':leave', [animate('1000ms cubic-bezier(0.23, 1, 0.32, 1)', style({transform: 'translateX(100%)'}))], {optional: true}),
      query(':enter', [animate('1000ms cubic-bezier(0.23, 1, 0.32, 1)', style({transform: 'translateX(0%)'}))], {optional: true})
    ]),
    query(':enter', animateChild(), {optional: true})
  ])
]);
