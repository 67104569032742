import {
  AfterViewInit,
  ComponentRef,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  QueryList,
  ViewContainerRef
} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {Subscription} from 'rxjs/internal/Subscription';
import {unsubscribe} from '../../../../../../alcedo/src/app/shared/decorators/unsubscribe.decorator';
import {AppInputErrorComponent} from '../app-form-field/app-input-error.component';
import {AppSelectComponent} from './app-select/app-select.component';

export const APP_SELECT_DATA = new InjectionToken('APP_SELECT_DATA');

@Directive({
  selector: '[appSelect]',
  standalone: true
})
export class AppSelectDirective implements AfterViewInit, OnDestroy {
  @Input() appSelectLabel: string;
  @ContentChild(MatSelect) matSelect: MatSelect;
  @ContentChildren(AppInputErrorComponent, {read: ElementRef}) errors: QueryList<ElementRef>;
  private componentRef: ComponentRef<AppSelectComponent>;
  private selectWrapper: HTMLDivElement;
  private errors$: Subscription;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    const injector = Injector.create({providers: [{provide: APP_SELECT_DATA, useValue: {matSelect: this.matSelect, label: this.appSelectLabel}}]});
    this.componentRef = this.viewContainerRef.createComponent(AppSelectComponent, {injector});
    this.componentRef.changeDetectorRef.detectChanges(); // This is needed because the other components have already been checked.

    this.elementRef.nativeElement.style.height = '0'; // Hide MatFormField by setting the height 0; the width is needed for the dropdown to work
    this.elementRef.nativeElement.style.display = 'grid'; // Set display grid for preventing flex having an effect
    this.elementRef.nativeElement.style.overflow = 'hidden'; // Hide the overflow caused by setting height 0
    this.selectWrapper = document.createElement('div'); // Create a wrapper to avoid setting the layout for 2 elements in the parent component
    this.elementRef.nativeElement.insertAdjacentElement('afterend', this.selectWrapper);
    this.selectWrapper.append(this.elementRef.nativeElement);
    this.selectWrapper.append(this.componentRef.location.nativeElement);

    this.addError(this.errors.first);
    this.observeErrorChanges();
  }

  ngOnDestroy(): void {
    this.selectWrapper.remove();
    unsubscribe(this);
  }

  private addError(errorRef: ElementRef): void {
    errorRef && this.componentRef.instance.errorContainer.nativeElement.append(errorRef.nativeElement);
  }

  private observeErrorChanges(): void {
    this.errors$ = this.errors.changes.subscribe((errors: QueryList<ElementRef>) => {
      this.componentRef.instance.errorContainer.nativeElement.firstChild?.remove();
      this.addError(errors.first);
    });
  }
}
